import React, { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Modal,
} from "react-bootstrap";
import backButtonImg from "../assets/backbutton.png";
import homeButtonImg from "../assets/home-button.png";
import axios from "axios";
import { API_BASE_URL } from "../apiConfig";

const AdminViewClients = () => {
  const location = useLocation();
  const { agentId } = location.state || {};
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [headers,setHeaders] = useState(null);

  const givenName = localStorage.getItem("givenName");
  const familyName = localStorage.getItem("familyName");
  const userId = localStorage.getItem("userId");
  const { getAuthHeaders } = useAuthContext();

  const handleSearch = (e) => setSearchTerm(e.target.value);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const headers = await getAuthHeaders();

        const clientResponse = await axios.get(`${API_BASE_URL}/clients`, {
          headers,
        });
        const allClients = clientResponse.data;
        const agentClients = allClients.filter(
          (client) => client.agent_id === agentId
        );

        const verificationResponse = await axios.get(
          `${API_BASE_URL}/verification`,
          { headers }
        );
        const allVerifications = verificationResponse.data;

        const clientsWithStatus = agentClients.map((client) => {
          const verification = allVerifications.find(
            (v) => v.client_id === client.client_id
          );
          return {
            ...client,
            status: verification
              ? verification.verification_status
              : "No Status Available",
          };
        });

        setClients(clientsWithStatus);
      } catch (error) {
        console.error(
          "Error fetching clients or verification statuses:",
          error
        );
      }
    };

    if (agentId) {
      fetchClients();
    }
  }, [agentId, getAuthHeaders]);

  const handleEditClick = async (client) => {
    try {
      // Fetch headers dynamically using getAuthHeaders
      const headers = await getAuthHeaders();
  
      // Make the API call with the required headers
      const response = await axios.get(
        `${API_BASE_URL}/clients/${client.client_id}?agentname=${givenName} ${familyName}&agentid=${userId}`,
        { headers }
      );
  
      // Navigate with client data if the API call succeeds
      navigate(`/update-client-profile`, { state: { clientData: response.data } });
    } catch (error) {
      console.error("Axios request failed:", error);
    }
  };

  const handleDeleteClick = (client) => {
    setSelectedClient(client);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const requestData = {
        deleted_by_id: userId,
        deleted_by_name: `${familyName} ${givenName}`,
      };

      const headers = await getAuthHeaders();

      const response = await axios.put(
        `${API_BASE_URL}/clients/delete/${selectedClient.client_id}`,
        requestData,
        {
          headers: {
            ...headers,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setClients(
          clients.filter(
            (client) => client.client_id !== selectedClient.client_id
          )
        );
        setShowDeleteModal(false);
      } else {
        alert("Error deleting profile.");
      }
    } catch (error) {
      alert("Error deleting profile.");
    }
  };

  const handleCancelDelete = () => setShowDeleteModal(false);

  const handleClose = () => setShowModal(false);

  const handleSendEmail = async () => {
    if (!selectedClientId) return;
    setLoadingEmail(true);
    try {
      const headers = await getAuthHeaders();
      const response = await axios.post(
        `${API_BASE_URL}/verification/email/${selectedClientId}`,
        { headers }
      );
      alert("Email sent to the client successfully.");
    } catch (error) {
      alert("Failed to send email to the client. Please try again.");
    } finally {
      setLoadingEmail(false);
      setShowModal(false);
    }
  };

  const handleStatusClick = (client) => {
    if (client.status === "Verified") {
      navigate("/view-verified-client-details", { state: { client } });
    } else if (client.status === "Pending") {
      navigate("/list-of-clients-for-approval");
    } else if (
      client.status === "Rejected" ||
      client.status === "Not Verified"
    ) {
      setSelectedClientId(client.client_id);
      setShowModal(true);
    }
  };

  return (
    <div
      style={{
        background:
          "linear-gradient(270deg, rgba(12,118,245,1) 0%, rgba(40,137,255,0.9304096638655462) 96%)",
        minHeight: "100vh",
        padding: "5px",
      }}
    >
      <Container fluid className="pt-3 pb-3 px-3">
        {/* Top Bar */}
        <Row className="mb-3 align-items-center">
          <Col xs={2} className="text-start">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate(-1)}
            >
              <img
                src={backButtonImg}
                alt="Back"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Button>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="search"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Col>
          <Col xs={2} className="text-end">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate("/")}
            >
              <img
                src={homeButtonImg}
                alt="Home"
                style={{
                  width: "75%",
                  height: "75%",
                  objectFit: "cover",
                  borderRadius: "0%",
                }}
              />
            </Button>
          </Col>
        </Row>

        {/* Clients and Agents buttons */}
        <Row className="mb-3">
          <Col xs={4}>
            <Button
              variant="light"
              className="w-100 rounded-4"
              style={{
                backgroundColor: "#FFFFFF",
                color: "#817F7F",
                border: "none",
              }}
              onClick={() => navigate("/admin-manage-admin-accounts")}
            >
              Admin
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              variant="light"
              className="w-100 rounded-4"
              style={{ backgroundColor: "#CFE3FD" }}
            >
              Agents
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              variant="light"
              className="w-100 rounded-4"
              style={{
                backgroundColor: "#FFFFFF",
                color: "#817F7F",
                border: "none",
              }}
              onClick={() => navigate("/admin-manage-client-accounts")}
            >
              Clients
            </Button>
          </Col>
        </Row>

        {/* Client List */}
        <Card
          className="mb-3"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <h4 className="mb-3 text-white" style={{ textAlign: "center" }}>
            VIEW CLIENTS
          </h4>
          <div
            className="text-white"
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <p>Agent ID: {agentId}</p>
          </div>
          <Row>
            <Col>
              {clients.length === 0 ? (
                <h5 className="text-center small" style={{ color: "red" }}>
                  No linked clients.
                </h5>
              ) : (
                clients.map((client) => {
                  const status = client.status;
                  return (
                    <Card
                      key={client.client_id}
                      className="mb-3"
                      style={{
                        borderRadius: "15px",
                        background:
                          " linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
                        border: "none",
                      }}
                    >
                      <Card.Body>
                        <Row className="align-items-center">
                          <Col>
                            <div className="d-flex flex-column flex-md-row align-items-md-center">
                              <p className="mb-0 me-md-5">
                                {client.first_name} {client.last_name}
                              </p>
                              <p className="mb-0">
                                Client ID: {client.client_id}
                              </p>
                            </div>
                            <p
                              onClick={() => handleStatusClick(client)}
                              className="mb-0 small"
                              style={{ color: "#0D75F4", cursor: "pointer" }}
                            >
                              Status: {status}
                            </p>
                            {status !== "Rejected" &&
                              status !== "Not Verified" &&
                              status !== "Pending" && (
                                <>
                                  <p
                                    onClick={() =>
                                      navigate(
                                        `/linked-accounts/${client.client_id}`
                                      )
                                    }
                                    className="mb-0"
                                    style={{
                                      color: "#0D75F4",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                  >
                                    View Linked Accounts
                                  </p>
                                  <p
                                    onClick={() =>
                                      navigate(
                                        `/transactions/${client.client_id}`
                                      )
                                    }
                                    className="mb-0"
                                    style={{
                                      color: "#0D75F4",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                  >
                                    View Transactions
                                  </p>
                                </>
                              )}
                            
                          </Col>

                          <Col
                            xs={2}
                            className="d-none d-md-flex flex-column align-items-end"
                          >
                            <Button
                              variant="outline-primary"
                              className="mb-2 rounded-4"
                              style={{ width: "100%" }}
                              onClick={() => handleEditClick(client)}
                            >
                              View / Edit
                            </Button>
                            <Button
                              variant="outline-danger"
                              className="mb-2 rounded-4"
                              style={{ width: "100%" }}
                              onClick={() => handleDeleteClick(client)}
                            >
                              Delete
                            </Button>
                          </Col>
                        </Row>

                        {/* Small screen buttons */}
                        <Row className="d-md-none mt-3">
                          <Col className="d-flex justify-content-between">
                            <Button
                              variant="outline-primary"
                              className="me-2 rounded-4"
                              style={{ flex: "1" }}
                              onClick={() => handleEditClick(client)}
                            >
                              View / Edit
                            </Button>
                            <Button
                              variant="outline-danger"
                              className="rounded-4"
                              style={{ flex: "1" }}
                              onClick={() => handleDeleteClick(client)}
                            >
                              Delete
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  );
                })
              )}
            </Col>
          </Row>
        </Card>
      </Container>

      {/* Modal for sending email */}
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>SEND EMAIL TO CLIENT?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to trigger an email to the client?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSendEmail}
            disabled={loadingEmail}
          >
            {loadingEmail ? "Sending..." : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleCancelDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>DELETE ACCOUNT?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete this Client Account? You can’t undo
            this action.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminViewClients;
