import React, { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import profileIcon from "../assets/profileIcon.png";
import backButtonImg from "../assets/backbutton.png";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../apiConfig";

const UpdateClientProfile = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const givenName = localStorage.getItem("givenName") || "N/A";
  const familyName = localStorage.getItem("familyName") || "N/A";
  const userId = localStorage.getItem("userId");
  const modifiedByName = `${givenName} ${familyName}`;

  const [errors, setErrors] = useState({});
  const { getAuthHeaders } = useAuthContext();

  const errorStyle = {
    border: "1px solid red",
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "firstName":
      case "lastName":
        if (!value) {
          error = "This field is required.";
        } else if (value.length < 2 || value.length > 50) {
          error = "Must be between 2 and 50 characters.";
        } else if (!/^[A-Za-z\s]+$/.test(value)) {
          error = "Must contain only alphabetic characters and spaces.";
        }
        break;
      case "dateOfBirth":
        const date = new Date(value);
        const age = new Date().getFullYear() - date.getFullYear();
        if (!value) {
          error = "This field is required.";
        } else if (date >= new Date()) {
          error = "Date must be in the past.";
        } else if (age < 18 || age > 100) {
          error = "Age must be between 18 and 100 years.";
        }
        break;
      case "gender":
        const allowedGenders = ["Male", "Female", "Non-binary", "Prefer not to say"];
        if (!value || !allowedGenders.includes(value)) {
          error = "Must be one of the predefined options.";
        }
        break;
      case "email":
        if (!value) {
          error = "This field is required.";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "Invalid email format.";
        }
        break;
      case "phone":
        if (!value) {
          error = "This field is required.";
        } else if (!/^\+\d{10,15}$/.test(value)) {
          error = "Must be a valid phone number (e.g., +1234567890).";
        }
        break;
      case "address":
        if (!value) {
          error = "This field is required.";
        } else if (value.length < 5 || value.length > 100) {
          error = "Must be between 5 and 100 characters.";
        }
        break;
      case "city":
      case "state":
      case "country":
        if (!value) {
          error = "This field is required.";
        } else if (value.length < 2 || value.length > 50) {
          error = "Must be between 2 and 50 characters.";
        }
        break;
      case "postalCode":
        if (!value) {
          error = "This field is required.";
        } else if (!/^\d{6}$/.test(value)) {
          error = "Enter a valid postal code of 6 digits.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const userDetails = {
    userId: user?.userId || "N/A",
    givenName,
    familyName,
  };

  const { clientData } = location.state || {};

  const [userData, setUserData] = useState({
    agentID: clientData?.agent_id || "",
    firstName: clientData?.first_name || "",
    lastName: clientData?.last_name || "",
    dateOfBirth: clientData?.date_of_birth || "",
    gender: clientData?.gender || "",
    email: clientData?.email_address || "",
    phone: clientData?.phone_number || "",
    address: clientData?.address || "",
    city: clientData?.city || "",
    state: clientData?.state || "",
    country: clientData?.country || "",
    postalCode: clientData?.postal_code || "",
    modifiedById: userDetails.userId,
    modifiedByName: `${userDetails.givenName} ${userDetails.familyName}`.trim(),
  });

  useEffect(() => {
    const handleStorageChange = () => {
      setUserData((prevUserData) => ({
        ...prevUserData,
        modifiedByName: `${localStorage.getItem("givenName") || "N/A"} ${
          localStorage.getItem("familyName") || "N/A"
        }`.trim(),
      }));
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    Object.keys(userData).forEach((field) => {
      const error = validateField(field, userData[field]);
      if (error) validationErrors[field] = error;
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      alert("Please fix the errors before submitting.");
      return;
    }

    const requestData = {
      first_name: userData.firstName,
      last_name: userData.lastName,
      date_of_birth: userData.dateOfBirth,
      gender: userData.gender,
      email_address: userData.email,
      phone_number: userData.phone,
      address: userData.address,
      city: userData.city,
      state: userData.state,
      country: userData.country,
      postal_code: userData.postalCode,
      agent_id: userData.agentID,
      modified_by_id: userData.modifiedById,
      modified_by_name: userData.modifiedByName,
    };

    try {
      const headers = await getAuthHeaders();

      const response = await axios.put(
        `${API_BASE_URL}/clients/${clientData.client_id}?agentname=${modifiedByName}&agentid=${userId}`,
        requestData,
        {
          headers: {
            ...headers,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        alert("Profile updated successfully!");
        navigate(-1);
      } else {
        alert("Error updating profile.");
      }
    } catch (error) {
      console.error("Error updating client profile:", error);
      alert("Phone number is in use. Please try again.");
    }
  };
  
  return (
    <div
      style={{ backgroundColor: "#DBEAFD", minHeight: "100vh", padding: "5px" }}
    >
      <Container fluid className="pt-3 pb-3 px-3">
        <Row className="mb-3 align-items-center">
          <Col xs={2} className="text-start">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate(-1)}
            >
              <img
                src={backButtonImg}
                alt="Back"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Button>
          </Col>
        </Row>
        <Row className="mb-4 text-center">
          <Col>
            <img
              src={profileIcon}
              alt="Profile Icon"
              className="img-fluid"
              style={{ maxWidth: "60px" }}
            />
            <h4 className="mt-3">CLIENT PROFILE</h4>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          {[
            "agentID",
            "email",
            "firstName",
            "lastName",
            "dateOfBirth",
            "gender",
            "phone",
            "address",
            "city",
            "state",
            "country",
            "postalCode",
          ].map((field) => (
            <Form.Group controlId={`form${field}`} className="mb-3" key={field}>
              {field === "gender" ? (
                <Form.Control
                  as="select"
                  name={field}
                  value={userData[field]}
                  onChange={(e) => {
                    handleInputChange(e);
                    const error = validateField(field, e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
                  }}
                  style={{
                    ...(errors[field] ? errorStyle : {}),
                    height: "50px",
                  }}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Non-binary">Non-binary</option>
                  <option value="Prefer not to say">Prefer not to say</option>
                </Form.Control>
              ) : field === "phone" ? (
                <Form.Control
                  type="text"
                  name={field}
                  value={userData[field]}
                  placeholder="Phone (e.g., +1234567890)"
                  onChange={(e) => {
                    handleInputChange(e);
                    const error = validateField(field, e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
                  }}
                  style={{
                    ...(errors[field] ? errorStyle : {}),
                    height: "50px",
                  }}
                />
              ) : (
                <Form.Control
                  type={field === "dateOfBirth" ? "date" : "text"}
                  name={field}
                  value={userData[field]}
                  placeholder={field.replace(/([A-Z])/g, " $1").trim()}
                  onChange={(e) => {
                    handleInputChange(e);
                    const error = validateField(field, e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
                  }}
                  disabled={field === "agentID" || field === "email"}
                  style={{
                    ...(errors[field] ? errorStyle : {}),
                    height: "50px",
                  }}
                />
              )}
              {errors[field] && <p style={{ color: "red" }}>{errors[field]}</p>}
            </Form.Group>
          ))}
          
          <div className="text-start">
            Modified by (name): {userData.modifiedByName}
          </div>
          <div className="text-start">
            Modified by (userId): {userData.modifiedById}
          </div>
          <Row className="mt-4">
            <Col xs={12} md={6}>
              <Button
                variant="primary"
                type="submit"
                className="w-100"
                style={{ height: "50px" }}
              >
                Update
              </Button>
            </Col>
            <Col xs={12} md={6} style={{ marginTop: "10px" }}>
              <Button
                variant="outline-secondary"
                type="button"
                className="w-100"
                onClick={() => navigate(-1)}
                style={{ height: "50px" }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
  
};

export default UpdateClientProfile;
