import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import profileIcon from "../assets/profileIcon.png";
import { useNavigate } from "react-router-dom";
import backButtonImg from "../assets/backbutton.png";

import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsmobile from "../aws-exports.js";
import AWS from "aws-sdk";
import { fetchAuthSession } from "aws-amplify/auth";

Amplify.configure(awsmobile);

const AdminCreateAgentPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const errorStyle = {
    border: "1px solid red",
  };

  const isValidEmail = (email) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(email) && !/\.\./.test(email);

  useEffect(() => {
    const fetchCurrentAuthenticatedUser = async () => {
      try {
        const user = await fetchAuthSession();
        const idToken = user.tokens?.idToken?.toString();
        setIdToken(idToken);
        return idToken;
      } catch (error) {
        console.error("Error fetching current authenticated user:", error);
        setAuthError("Could not fetch the current authenticated user.");
        return null;
      }
    };
    const fetchData = async () => {
      await fetchCurrentAuthenticatedUser();
    };
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "email" && !isValidEmail(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: true }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (Object.values(errors).some((error) => error)) {
      alert("Please fix the errors before submitting.");
      setLoading(false);
      return;
    }

    try {
      if (!idToken) {
        throw new Error("Unable to get authentication token. Please log in.");
      }

      AWS.config.region = process.env.REACT_APP_AWS_REGION;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        RoleArn:
          process.env.REACT_APP_AUTH_ROLE,
        Logins: {
          [process.env.REACT_APP_AUTH_LOGIN]:
            idToken,
        },
      });

      const params = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        Username: formData.email,
        TemporaryPassword: "A!strongPassword123",
        UserAttributes: [
          { Name: "email", Value: formData.email },
          { Name: "email_verified", Value: "true" },
          { Name: "given_name", Value: formData.firstName },
          { Name: "family_name", Value: formData.lastName },
        ],
      };

      const cognitoidentityserviceprovider =
        new AWS.CognitoIdentityServiceProvider();

      cognitoidentityserviceprovider.adminCreateUser(
        params,
        function (err, result) {
          if (err) {
            console.error("Error creating new user:", err);
            alert("Failed to create agent. Please try again.");
          } else {

            const groupParams = {
              GroupName: "agents",
              UserPoolId: process.env.REACT_APP_USER_POOL_ID,
              Username: formData.email,
            };

            cognitoidentityserviceprovider.adminAddUserToGroup(
              groupParams,
              function (err, data) {
                if (err) {
                  console.error("Error adding user to group:", err);
                  alert("Failed to add user to group. Please try again.");
                } else {
                  alert(
                    "Agent profile created successfully and added to group!"
                  );
                  navigate(-1);
                }
              }
            );
          }
        }
      );
    } catch (error) {
      console.error("Error in submission:", error);
      alert("Failed to create agent. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (authError) {
    return <div>Error: {authError}</div>;
  }

  return (
    <Authenticator>
      {({ signOut, user }) => (
        <div
          style={{
            backgroundColor: "#DBEAFD",
            minHeight: "100vh",
            padding: "5px",
          }}
        >
          <Container fluid className="pt-3 pb-3 px-3">
            <Row className="mb-3 align-items-center">
              <Col xs={2} className="text-start">
                <Button
                  variant="light"
                  className="p-0"
                  style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                  onClick={() => navigate(-1)}
                >
                  <img
                    src={backButtonImg}
                    alt="Back"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                </Button>
              </Col>
            </Row>
            <Col className="">
              <Row className="mb-4 text-center">
                <Col>
                  <img
                    src={profileIcon}
                    alt="Profile Icon"
                    className="img-fluid"
                    style={{ maxWidth: "60px" }}
                  />
                  <h4 className="mt-3">AGENT PROFILE</h4>
                </Col>
              </Row>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formFirstName" className="mb-3">
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    placeholder="First Name"
                    onChange={handleInputChange}
                    style={{ height: "50px" }}
                  />
                </Form.Group>
                <Form.Group controlId="formLastName" className="mb-3">
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    placeholder="Last Name"
                    onChange={handleInputChange}
                    style={{ height: "50px" }}
                  />
                </Form.Group>
                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    placeholder="Email"
                    onChange={handleInputChange}
                    style={{
                      ...(errors.email ? errorStyle : {}),
                      height: "50px",
                    }}
                  />
                </Form.Group>
                <Row className="mt-4">
                  <Col xs={12} md={6}>
                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      disabled={loading}
                      style={{ height: "50px" }}
                    >
                      {loading ? "Creating..." : "Create"}
                    </Button>
                  </Col>
                  <Col xs={12} md={6} style={{ marginTop: "10px" }}>
                    <Button
                      variant="outline-secondary"
                      type="button"
                      className="w-100"
                      onClick={() => navigate(-1)}
                      disabled={loading}
                      style={{ height: "50px" }}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Container>
        </div>
      )}
    </Authenticator>
  );
};

export default AdminCreateAgentPage;
