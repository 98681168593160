import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import backButtonImg from "../assets/backbutton.png";
import axios from "axios";
import { API_BASE_URL } from "../apiConfig";
import { useAuthContext } from "../context/AuthContext";

const ClientLinkedAcc = () => {
  const navigate = useNavigate();
  const { client_id } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State to control the visibility of the delete modal
  const [selectedAccount, setSelectedAccount] = useState(null); // State to store which client is being deleted
  const [accounts, setAccounts] = useState([]);
  const [clientName, setClientName] = useState("");
  const [agentId, setAgentId] = useState("");
  const [error, setError] = useState(null);

  const givenName = localStorage.getItem("givenName");
  const familyName = localStorage.getItem("familyName");
  const userId = localStorage.getItem("userId");
  const modifiedByName = `${givenName} ${familyName}`;

  const { getAuthHeaders } = useAuthContext();

  const fetchClientDetails = useCallback(async () => {
    try {
      const headers = await getAuthHeaders();

      const clientResponse = await axios.get(
        `${API_BASE_URL}/clients/${client_id}?agentname=${modifiedByName}&agentid=${userId}`,
        { headers }
      );
      const clientData = clientResponse.data;
      setClientName(`${clientData.first_name} ${clientData.last_name}`);
      setAgentId(clientData.agent_id);
      setError(null);
    } catch (error) {
      setError("Error fetching client details");
      console.error("Error fetching client details:", error);
    }
  }, [client_id, getAuthHeaders]);

  const fetchLinkedAccounts = useCallback(async () => {
    try {
      const headers = await getAuthHeaders();

      const accountsResponse = await axios.get(
        `${API_BASE_URL}/accounts/${client_id}`,
        { headers }
      );
      setAccounts(accountsResponse.data);
      setError(null);
    } catch (error) {
      console.error("Error fetching linked accounts:", error);
      setError("No linked accounts found for this client.");
    }
  }, [client_id, getAuthHeaders]);

  useEffect(() => {
    if (client_id) {
      fetchClientDetails();
      fetchLinkedAccounts();
    } else {
      setError("Client ID not found");
    }
  }, [client_id, fetchClientDetails, fetchLinkedAccounts]);

  const handleCreateAccountClick = () => {
    navigate("/create-client-account");
  };

  const handleDeleteClick = (accountId) => {
    setSelectedAccount(accountId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const headers = await getAuthHeaders();

      const requestData = {
        deleted_by_id: userId,
        deleted_by_name: `${familyName} ${givenName}`,
      };

      const response = await axios.put(
        `${API_BASE_URL}/account/${selectedAccount}`,
        requestData,
        {
          headers: {
            ...headers,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setAccounts(
          accounts.filter((account) => account.account_id !== selectedAccount)
        );
      } else {
        console.error(`Failed to delete Account ID ${selectedAccount}`);
        setError("Failed to delete account");
      }

      setShowDeleteModal(false);
    } catch (error) {
      console.error(`Error deleting Account ID ${selectedAccount}:`, error);
      setError("Error deleting account");
      setShowDeleteModal(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  return (
    <div
      style={{ backgroundColor: "#DBEAFD", minHeight: "100vh", padding: "5px" }}
    >
      <Container fluid className="pt-3 pb-3 px-3">
        <Row className="mb-3 align-items-center">
          <Col xs={2} className="text-start">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate(-1)}
            >
              <img
                src={backButtonImg}
                alt="Back"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Button>
          </Col>
        </Row>

        {/* Client Information */}
        <Row className="justify-content-center mb-3">
          <Col xs={12} md={8} className="text-center">
            <div
              className="p-3"
              style={{ backgroundColor: "#DBEAFD", borderRadius: "15px" }}
            >
              <h4 className="mb-0">
                <strong>{clientName || "Loading..."}</strong>
              </h4>
              <p className="mb-0">Client ID: {client_id}</p>
              <p className="mb-0">Agent ID: {agentId}</p>
            </div>
          </Col>
        </Row>

        {/* Accounts Section */}
        <Row className="justify-content-center">
          <Col>
            <div
              className="mb-3 mt-2 p-3 w-100 rounded-4"
              style={{ backgroundColor: "#FFFFFF", borderRadius: "15px" }}
            >
              <h5 className="text-center mb-3">
                <strong>LINKED ACCOUNTS</strong>
              </h5>
              {accounts.length === 0 ? (
                <p className="text-center">No linked accounts found.</p>
              ) : (
                accounts.map((account) => (
                  <ListGroupItem
                    key={account.account_id}
                    className="d-flex justify-content-between align-items-center mb-2"
                    style={{ textAlign: "left" }}
                  >
                    <div>
                      <strong>{account.account_type}</strong>
                      <p className="mb-0">Account ID: {account.account_id}</p>
                    </div>
                    <Button
                      variant="outline-danger"
                      className="rounded-4"
                      onClick={() => handleDeleteClick(account.account_id)}
                    >
                      Delete
                    </Button>
                  </ListGroupItem>
                ))
              )}
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={12} md={6}>
            <Button
              variant="light"
              className=" w-100"
              style={{
                color: "#FFFFFF",
                backgroundColor: "#0D75F4",
                whiteSpace: "nowrap",
                height: "50px",
              }}
              onClick={handleCreateAccountClick}
            >
              CREATE NEW CLIENT ACCOUNT
            </Button>
          </Col>
        </Row>

        <Modal show={showDeleteModal} onHide={handleCancelDelete} centered>
          <Modal.Header closeButton>
            <Modal.Title>DELETE ACCOUNT?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this Client Account?</p>
            <p>You can’t undo this action.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancelDelete}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default ClientLinkedAcc;
