import React, { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  Button 
} from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import homeButtonImg from "../assets/home-button.png";

const ListOfClientsforApproval = ({ user, userGroup }) => {
  const [pendingClients, setPendingClients] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const { getAuthHeaders } = useAuthContext();
  const agentId = user?.userId || "N/A";

  const fetchVerifications = async () => {
    try {
      const headers = await getAuthHeaders();

      // Fetch all verification records
      const verificationResponse = await axios.get(`https://api.itsag1t4.com/verification`, { headers });
      const verificationData = verificationResponse.data || [];

      // Filter verifications with "Pending" status
      const filteredVerifications = verificationData.filter(
        verification => verification.verification_status === "Pending"
      );

      // Fetch all client details
      const clientDetailsResponse = await axios.get(`https://api.itsag1t4.com/clients`, { headers });
      const allClients = clientDetailsResponse.data || [];

      let agentClients;
      if (userGroup === "agents") {
        // Filter clients associated with the logged-in agent
        agentClients = filteredVerifications
          .filter(verification => {
            const clientDetail = allClients.find(
              client => client.client_id === verification.client_id
            );
            return clientDetail && clientDetail.agent_id === agentId;
          })
          .map(verification => {
            const clientDetail = allClients.find(
              client => client.client_id === verification.client_id
            );
            return { ...clientDetail, verification_id: verification.verification_id };
          });
      } else if (userGroup === "root_admin") {
        // Admins see all pending verifications
        agentClients = filteredVerifications.map(verification => {
          const clientDetail = allClients.find(
            client => client.client_id === verification.client_id
          );
          return clientDetail ? { ...clientDetail, verification_id: verification.verification_id } : null;
        }).filter(client => client !== null);
      }

      setPendingClients(agentClients);
    } catch (error) {
      console.error("Error fetching verifications or client details:", error);
    }
  };

  useEffect(() => {
    fetchVerifications();
  }, [location.state?.refresh, agentId, userGroup]);

  return (
    <div
      style={{
        minHeight: "100vh",
        padding: "5px",
        background:
          "linear-gradient(270deg, rgba(12,118,245,1) 0%, rgba(40,137,255,0.9304096638655462) 96%)",
      }}
    >
      <Container className="pt-3 pb-3 px-3">
      {/* Home Button Positioned to the Top Right */}
      <Row className="mb-3 align-items-center justify-content-center">
        <Col xs={1} className="text-start">
        </Col>
        <Col xs={8} className="text-center">
          <h4 className="mb-3 mt-4 text-white">Clients Ready for Verification</h4>
        </Col>
        <Col xs="auto" className="text-end">
          <Button
            variant="light"
            className="p-0"
            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
            onClick={() => navigate("/")}
          >
            <img
              src={homeButtonImg}
              alt="Home"
              style={{ width: "75%", height: "75%", objectFit: "cover" }}
            />
          </Button>
        </Col>
      </Row>
        <Row 
          xs={1} 
          md={2} 
          lg={3} 
          className="g-4"
        >
          {pendingClients && pendingClients.length > 0 ? (
            pendingClients.map((client) => (
              <Col key={client.client_id}>
                <Card 
                  className="h-100 shadow" 
                  style={{ 
                    backgroundColor: "#DBEAFD", 
                    padding: "20px", 
                    borderRadius: "15px" 
                  }}
                >
                  <Card.Body>
                    <Card.Title><strong>Client ID: {client.client_id}</strong></Card.Title>
                    <p>Verification ID: <strong>{client.verification_id}</strong></p>
                  </Card.Body>
                  <Card.Footer 
                    className="d-flex justify-content-end border-top-0" 
                    style={{ backgroundColor: "#DBEAFD" }}
                  >
                    <Button
                      variant="light"
                      size="sm"
                      onClick={() => navigate(`/approve-client-verification`, { state: { client } })}
                    >
                      View Details
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            ))
          ) : (
            <p className="mb-3 mt-4 text-white" style={{ textAlign: "center" }}>
              No clients available for verification.
            </p>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default ListOfClientsforApproval;
