import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import checkIcon from "../assets/checkIcon.png"; // Replace with your actual check icon path
import { useNavigate } from "react-router-dom"; // For navigating to login page
import { signOut } from "@aws-amplify/auth";

const ConfirmResetPassword = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = async () => {
    try {
      await signOut(); // Call the signOut function directly
      navigate("/"); // Navigate back to the default route
    } catch (error) {
      console.error("Error logging out", error);
    }
  };
  

  return (
    <div 
      style={{ 
        backgroundColor: "#DBEAFD", 
        minHeight: "100vh", 
        padding: "5px"
      }}
    >
        <Container fluid className="d-flex justify-content-center align-items-center min-vh-100" >
        <Row className="w-100 justify-content-center">
            <Col xl={8} className="p-4 text-center">
            <img 
              src={checkIcon} 
              alt="Check Icon" 
              className="img-fluid mb-4" 
              style={{ 
                maxWidth: "100px" 
              }} 
            />
            <h4 className="mb-3">PASSWORD UPDATED</h4>
            <p>Your password has been reset!</p>
            <Button 
              variant="primary" 
              onClick={handleLoginRedirect} 
              className="w-100"
            >
                Login
            </Button>
            </Col>
        </Row>
        </Container>
    </div>
  );
};

export default ConfirmResetPassword;

