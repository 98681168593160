import React, { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { 
  Container, 
  Row,  
  Col, 
  Card, 
  Form, 
  Image, 
  Button 
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import backButtonImg from "../assets/backbutton.png";

const ViewVerifiedClientDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [clientDetails, setClientDetails] = useState(null);
  const [s3Url, setS3Url] = useState(null); // State for the S3 URL
  const client = location.state?.client;
  const givenName = localStorage.getItem("givenName");
  const familyName = localStorage.getItem("familyName");
  const userId = localStorage.getItem("userId");
  const modifiedByName = `${givenName} ${familyName}`;
  const { getAuthHeaders } = useAuthContext();

  useEffect(() => {
    if (client) {
      // Fetch client details and verification details to get the S3 URL
      const fetchClientAndVerificationDetails = async () => {
        try {
          const headers = await getAuthHeaders();

          // Fetch client details
          const clientResponse = await axios.get(`https://api.itsag1t4.com/clients/${client.client_id}?agentname=${modifiedByName}&agentid=${userId}`, { headers });
          setClientDetails(clientResponse.data); // Update state with client details

          // Fetch verification details to get the S3 URL
          const verificationResponse = await axios.get(`https://api.itsag1t4.com/verification`, { headers });
          const verification = verificationResponse.data.find(v => v.client_id === client.client_id);

          if (verification) {
            setS3Url(verification.s3_url); // Set the S3 URL from the verification data
          } else {
            console.error("No verification details found for this client");
          }
        } catch (error) {
          console.error("Error fetching client or verification details:", error);
        }
      };

      fetchClientAndVerificationDetails();
    }
  }, [client, getAuthHeaders]);

  if (!client) {
    return <div>Client not found</div>;
  }

  if (!clientDetails || !s3Url) {
    return <div>Loading client details...</div>;
  }

  return (
    <div
      style={{
        minHeight: "100vh",
        padding: "5px",
        background:
          "linear-gradient(270deg, rgba(12,118,245,1) 0%, rgba(40,137,255,0.9304096638655462) 96%)",
      }}
    >
      <Container className="pt-3 pb-3 px-3">
        <Row className="mb-3 align-items-center">
          <Col xs={2} className="text-start">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate(-1)} // Go back to the previous page
            >
              <img 
                src={backButtonImg} 
                alt="Back" 
                style={{ 
                  width: "100%", 
                  height: "100%", 
                  objectFit: "cover", 
                  borderRadius: "50%" 
                }} 
              />
            </Button>
          </Col>
          <Col xs={8}>
          <h4 className="mb-3 mt-4 text-white" style={{ textAlign: "center" }}>
              Verified Client: {clientDetails.first_name} {clientDetails.last_name}
            </h4>
            <div style={{ width: "45px" }}></div> {/* Empty div to balance the layout */}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Card className="mb-4 rounded-4">
              <Card.Header as="h6">NRIC</Card.Header>
              <Card.Body>
                {s3Url ? (
                  <Image src={s3Url} alt="NRIC" fluid /> // Use the S3 URL for the NRIC image
                ) : (
                  <p>No NRIC image available.</p>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-4">
              <Card.Header as="h6">Client Details</Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={`${clientDetails.first_name} ${clientDetails.last_name}`} 
                      readOnly 
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control 
                      as="textarea" 
                      rows={3} 
                      value={clientDetails.address || "NA"} 
                      readOnly 
                    />
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ViewVerifiedClientDetails;
