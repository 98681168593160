import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AWS from "aws-sdk";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import {
  Authenticator,
  ThemeProvider,
  createTheme,
  View,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import loginImage from "./assets/banklogo.png";
import { fetchAuthSession } from "aws-amplify/auth";
import { AuthProvider } from "./context/AuthContext";

import ResetPasswordPage from "./pages/ResetPasswordPage";
import ConfirmResetPage from "./pages/ConfirmResetPage";
import AdminDashboardPage from "./pages/AdminDashboardPage";
import AdminCreateAgentPage from "./pages/AdminCreateAgentPage";
import AdminUpdateAgentPage from "./pages/AdminUpdateAgentPage";
import AdminManageClientAccPage from "./pages/AdminManageClientAccPage";
import AgentDashboardPage from "./pages/AgentDashboardPage";
import CreateClientProfilePage from "./pages/CreateClientProfilePage";
import CreateClientAccPage from "./pages/CreateClientAccPage";
import AgentManageAccPage from "./pages/AgentManageAccPage";
import TransactionPage from "./pages/TransactionPage";
import AdminManageAgentAccPage from "./pages/AdminManageAgentAccPage";
import ClientVerification from "./pages/ClientVerification";
import UpdateClientProfilePage from "./pages/UpdateClientProfilePage";
import ApproveClientVerification from "./pages/ApproveClientVerification";
import ListOfClientsforApproval from "./pages/ListOfClientsforApproval";
import ViewVerifiedClientDetails from "./pages/ViewVerifiedClientDetails";
import AdminManageAdminAccPage from "./pages/AdminManageAdminAccPage";
import AdminViewClients from "./pages/AdminViewClientPage";
import ClientLinkedAcc from "./pages/ClientLinkedAcc";
import AllTransacPage from "./pages/AllTransacPage";
import RootCreateAdminPage from "./pages/RootCreateAdminPage";
import RootUpdateAdminPage from "./pages/RootUpdateAdminPage";

Amplify.configure(awsExports);

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

fetch("https://tp80e3en28.execute-api.ap-southeast-1.amazonaws.com/dev/api/key")
  .then((response) => response.json())
  .then((data) => {
    const { keyID, key } = data;
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: keyID,
      secretAccessKey: key,
    });
  })
  .catch((error) => {
    console.error("Error fetching credentials:", error);
  });

const theme = createTheme({
  name: "custom-theme",
  tokens: {
    components: {
      button: {
        primary: {
          backgroundColor: { value: "#0D75F4" },
          color: { value: "#FFFFFF" },
          _hover: {
            backgroundColor: { value: "#CDEBFE" },
          },
        },
        link: {
          color: { value: "#1055C4" },
          _hover: {
            color: { value: "#0D47A1" },
          },
        },
      },
    },
  },
});

const TIMEOUT_DURATION = 5 * 60 * 1000;
let idleTimeout;

const App = () => {
  // useEffect(() => {
  //   fetch(
  //     "https://tp80e3en28.execute-api.ap-southeast-1.amazonaws.com/dev/api/key"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const { keyID, key } = data;
  //       AWS.config.update({
  //         region: process.env.REACT_APP_AWS_REGION,
  //         accessKeyId: keyID,
  //         secretAccessKey: key,
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching credentials:", error);
  //     });
  // });

  const [userGroup, setUserGroup] = useState(null);
  const [user, setUser] = useState(null);
  const [userDetailsFetched, setUserDetailsFetched] = useState(false);
  const [idToken, setIdToken] = useState(null);
  const [isTokenLogged, setIsTokenLogged] = useState(false);
  const [signOutFn, setSignOutFn] = useState(null);

  useEffect(() => {
    const resetTimer = () => {
      clearTimeout(idleTimeout);
      idleTimeout = setTimeout(() => {
        console.log("Signing out due to inactivity...");
        localStorage.clear();
        sessionStorage.clear();
        setUser(null);
        setUserGroup(null);
      }, TIMEOUT_DURATION);
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    resetTimer(); // Initialize timer

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      clearTimeout(idleTimeout);
    };
  }, []);

  const handleSetSignOutFn = (signOut) => {
    if (!signOutFn && typeof signOut === "function") {
      setSignOutFn(() => () => {
        localStorage.clear();
        sessionStorage.clear();
        setUser(null);
        setUserGroup(null);
        setUserDetailsFetched(false);
        sessionStorage.setItem("hasLoggedIn", "false"); // Set session storage flag
        signOut();
        console.log("Signing out...");
        window.location.reload();
      });
    }
  };

  useEffect(() => {
    const getIdToken = async () => {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens?.idToken?.toString();
        if (token && !isTokenLogged) {
          setIdToken(token);
          setIsTokenLogged(true);
        }
      } catch (error) {}
    };
    if (user) getIdToken();
  }, [user, isTokenLogged]);

  useEffect(() => {
    const fetchUserGroups = async (username) => {
      const cognitoIdp = new AWS.CognitoIdentityServiceProvider();
      const params = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        Username: username,
      };
      try {
        const data = await cognitoIdp.adminListGroupsForUser(params).promise();
        const groups = data.Groups.map((group) => group.GroupName);
        setUserGroup(groups.includes("root_admin") ? "root_admin" : "agents");
        const userData = await cognitoIdp.adminGetUser(params).promise();
        const userId = userData.UserAttributes.find(
          (attr) => attr.Name === "sub"
        )?.Value;
        const givenName = userData.UserAttributes.find(
          (attr) => attr.Name === "given_name"
        )?.Value;
        const familyName = userData.UserAttributes.find(
          (attr) => attr.Name === "family_name"
        )?.Value;

        if (userId) {
          localStorage.setItem("userId", userId);
          localStorage.setItem("username", username);
          localStorage.setItem("givenName", givenName);
          localStorage.setItem("familyName", familyName);

          setUser({ userId, givenName, familyName, username });
          setUserDetailsFetched(true);
        }
      } catch (error) {
        console.error("Error fetching user groups:", error);
      }
    };

    if (user && !userDetailsFetched) {
      fetchUserGroups(user.username);
    }
  }, [user, userDetailsFetched]);

  useEffect(() => {
    const hasLoggedIn = sessionStorage.getItem("hasLoggedIn");

    if (user && hasLoggedIn !== "true") {
      sessionStorage.setItem("hasLoggedIn", "true");
      window.location.reload();
    }
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <Authenticator
        hideSignUp={true}
        components={{
          SignIn: {
            Header() {
              return (
                <View
                  textAlign="center"
                  padding="20px 2rem"
                  style={{ marginTop: "50px" }}
                >
                  <img
                    src={loginImage}
                    alt="Login"
                    style={{
                      width: "100%",
                      maxHeight: "250px",
                      objectFit: "contain",
                    }}
                  />
                </View>
              );
            },
          },
        }}
      >
        {({ signOut, user: authUser }) => {
          handleSetSignOutFn(signOut);
          if (authUser !== user) {
            setUser(authUser);
          }

          if (!userGroup) {
            return <div>Loading...</div>;
          }

          return (
            <AuthProvider>
              <Router>
                <div>
                  <Routes>
                    {userGroup === "root_admin" && (
                      <>
                        <Route
                          path="/"
                          element={
                            <AdminDashboardPage
                              user={authUser}
                              signOut={signOut}
                            />
                          }
                        />
                        <Route
                          path="/reset-password"
                          element={<ResetPasswordPage />}
                        />
                        <Route
                          path="/confirm-reset"
                          element={<ConfirmResetPage />}
                        />
                        <Route
                          path="/admin-create-agent"
                          element={<AdminCreateAgentPage />}
                        />
                        <Route
                          path="/admin-update-agent"
                          element={<AdminUpdateAgentPage />}
                        />
                        <Route
                          path="/admin-manage-client-accounts"
                          element={<AdminManageClientAccPage />}
                        />
                        <Route
                          path="/admin-manage-agent-accounts"
                          element={<AdminManageAgentAccPage />}
                        />
                        <Route
                          path="/admin-manage-admin-accounts"
                          element={<AdminManageAdminAccPage />}
                        />
                        <Route
                          path="/create-client-profile"
                          element={<CreateClientProfilePage user={user} />}
                        />
                        <Route
                          path="/create-client-account"
                          element={<CreateClientAccPage user={user} />}
                        />
                        <Route
                          path="/transactions/:client_id"
                          element={<TransactionPage />}
                        />
                        <Route
                          path="/update-client-profile"
                          element={
                            <UpdateClientProfilePage
                              user={user}
                              signOut={signOut}
                            />
                          }
                        />
                        <Route
                          path="/admin-view-client"
                          element={<AdminViewClients />}
                        />
                        <Route
                          path="/client-verification"
                          element={<ClientVerification />}
                        />
                        <Route
                          path="/all-transactions"
                          element={
                            <AllTransacPage
                              user={authUser}
                              userGroup={userGroup}
                              signOut={signOut}
                            />
                          }
                        />
                        <Route
                          path="/approve-client-verification"
                          element={<ApproveClientVerification />}
                        />
                        <Route
                          path="/list-of-clients-for-approval"
                          element={
                            <ListOfClientsforApproval
                              user={user}
                              userGroup={userGroup}
                            />
                          }
                        />
                        <Route
                          path="/view-verified-client-details"
                          element={<ViewVerifiedClientDetails />}
                        />
                        <Route
                          path="/root-create-admin"
                          element={<RootCreateAdminPage />}
                        />
                        <Route
                          path="/root-update-admin"
                          element={<RootUpdateAdminPage />}
                        />
                        <Route
                          path="/linked-accounts/:client_id"
                          element={<ClientLinkedAcc />}
                        />
                      </>
                    )}
                    {userGroup === "agents" && (
                      <>
                        <Route
                          path="/"
                          element={
                            <AgentDashboardPage
                              user={authUser}
                              signOut={signOut}
                            />
                          }
                        />
                        <Route
                          path="/reset-password"
                          element={<ResetPasswordPage />}
                        />
                        <Route
                          path="/confirm-reset"
                          element={<ConfirmResetPage />}
                        />
                        <Route
                          path="/create-client-profile"
                          element={
                            <CreateClientProfilePage
                              user={user}
                              signOut={signOut}
                            />
                          }
                        />
                        <Route
                          path="/create-client-account"
                          element={<CreateClientAccPage user={user} />}
                        />
                        <Route
                          path="/agent-manage-accounts"
                          element={<AgentManageAccPage user={user} />}
                        />
                        <Route
                          path="/transactions/:client_id"
                          element={<TransactionPage />}
                        />
                        <Route
                          path="/all-transactions"
                          element={
                            <AllTransacPage
                              user={authUser}
                              userGroup={userGroup}
                              signOut={signOut}
                            />
                          }
                        />
                        <Route
                          path="/update-client-profile"
                          element={
                            <UpdateClientProfilePage
                              user={user}
                              signOut={signOut}
                            />
                          }
                        />
                        <Route
                          path="/client-verification"
                          element={<ClientVerification />}
                        />
                        <Route
                          path="/approve-client-verification"
                          element={<ApproveClientVerification />}
                        />
                        <Route
                          path="/list-of-clients-for-approval"
                          element={
                            <ListOfClientsforApproval
                              user={user}
                              userGroup={userGroup}
                            />
                          }
                        />
                        <Route
                          path="/view-verified-client-details"
                          element={<ViewVerifiedClientDetails />}
                        />
                        <Route
                          path="/linked-accounts/:client_id"
                          element={<ClientLinkedAcc />}
                        />
                      </>
                    )}
                  </Routes>
                </div>
              </Router>
            </AuthProvider>
          );
        }}
      </Authenticator>
    </ThemeProvider>
  );
};

export default App;
