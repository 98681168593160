import React, { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  InputGroup,
  FormControl,
  Badge,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import searchIcon from "../assets/searchicon.png";
import dollarIcon from "../assets/dollarCoin.png";
import backButtonImg from "../assets/backbutton.png";
import homeButtonImg from "../assets/home-button.png";
import axios from "axios";
import { API_BASE_URL } from "../apiConfig";

const TransactionPage = () => {
  const { client_id } = useParams();
  const navigate = useNavigate();

  const [client, setClient] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const givenName = localStorage.getItem("givenName") || "N/A";
  const familyName = localStorage.getItem("familyName") || "N/A";
  const userId = localStorage.getItem("userId");
  const modifiedByName = `${givenName} ${familyName}`;
  const { getAuthHeaders } = useAuthContext();

  // Fetch transactions from the backend
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const headers = await getAuthHeaders();
        // Fetch all transactions
        const response = await axios.get(`${API_BASE_URL}/transactions`, {
          headers,
        });
        const allTransactions = Array.isArray(response.data)
          ? response.data
          : [];
        // Filter transactions to show only those matching the client_id
        const filteredTransactions = allTransactions.filter(
          (transaction) => transaction.client_id === parseInt(client_id)
        );
        setTransactions(filteredTransactions);
        //         const response = await axios.get(`${API_BASE_URL}/transactions/${client_id}`, { headers });
        //         setTransactions(response.data);
      } catch (error) {
        console.error("Error fetching transactions:", error);
        setError("Failed to load transactions.");
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [client_id]);

  // Fetch client data from backend
  useEffect(() => {
    const fetchClient = async () => {
      try {
        const headers = await getAuthHeaders();

        const response = await axios.get(
          `${API_BASE_URL}/clients/${client_id}?agentname=${modifiedByName}&agentid=${userId}`,
          { headers }
        );
        setClient(response.data);
      } catch (error) {
        console.error("Error fetching client data:", error);
        setError("Failed to load client data.");
      }
    };

    fetchClient();
  }, [client_id]);

  // Filter transactions based on search term (status)
  const filteredTransactions = transactions.filter((transaction) =>
    transaction.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const renderTransaction = (transaction) => (
    <Row className="mb-3 p-1 pb-1" key={transaction.transaction_id}>
      <Col xs={12}>
        <Card
          className="p-3"
          style={{
            background:
              " linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
            borderRadius: "15px",
            border: "none",
            minHeight: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <p className="w-100 small text-start" style={{ marginBottom: "5px" }}>
            <strong>Transaction ID:</strong> {transaction.transaction_id}
          </p>
          <Row className="w-100 d-flex align-items-center justify-content-between">
            <Col xs={5} className="text-start" style={{ paddingLeft: "0" }}>
              <div className="d-flex align-items-center justify-content-start w-100">
                <img
                  src={dollarIcon}
                  alt="Dollar Icon"
                  style={{ width: "20px", height: "20px", marginRight: "5px" }}
                />
                <h5 className="mb-0 small">${transaction.amount.toFixed(2)}</h5>
              </div>
            </Col>
            <Col xs={5} className="text-end">
              <Badge
                bg={
                  transaction.status === "Completed"
                    ? "success"
                    : transaction.status === "Failed"
                    ? "danger"
                    : "warning"
                }
                pill
              >
                {transaction.status}
              </Badge>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );

  if (loading) {
    return <p className="text-center">Loading transactions...</p>;
  }

  return (
    <div
      style={{
        minHeight: "100vh",
        padding: "5px",
        background:
          "linear-gradient(270deg, rgba(12,118,245,1) 0%, rgba(40,137,255,0.9304096638655462) 96%)",
      }}
    >
      <Container fluid className="pt-3 pb-3 px-3">
        {/* Top Bar: Back Button, Search, and Home Button */}
        <Row className="mb-3 align-items-center">
          <Col xs={2} className="text-start">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate(-1)}
            >
              <img
                src={backButtonImg}
                alt="Back"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Button>
          </Col>
          <Col xs={8}>
            <InputGroup>
              <InputGroup.Text className="bg-white">
                <img
                  src={searchIcon}
                  alt="Search"
                  style={{ width: "20px", height: "20px" }}
                />
              </InputGroup.Text>
              <FormControl
                placeholder="Search by status"
                value={searchTerm}
                onChange={handleSearch}
              />
            </InputGroup>
          </Col>
          <Col xs={2} className="text-end">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate("/")}
            >
              <img
                src={homeButtonImg}
                alt="Home"
                style={{
                  width: "75%",
                  height: "75%",
                  objectFit: "cover",
                  borderRadius: "0%",
                }}
              />
            </Button>
          </Col>
        </Row>

        {client && (
          <Card
            className="text-center mb-3 p-3"
            style={{
              background:
                " linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
              borderRadius: "15px",
              border: "none",
            }}
          >
            <Card.Body>
              <Card.Title>
                <strong>
                  {client.first_name} {client.last_name}
                </strong>
              </Card.Title>
              <Card.Text>
                <strong>Client ID:</strong> {client.client_id}
              </Card.Text>
            </Card.Body>
          </Card>
        )}

        <Card
          className="mb-3"
          style={{
            backgroundColor: "transparent",
            maxHeight: "800px",
            border: "none",
          }}
        >
          <h5 className="text-center text-white mb-3">
            <strong>TRANSACTIONS</strong>
          </h5>

          {filteredTransactions.length > 0 ? (
            filteredTransactions.map(renderTransaction)
          ) : (
            <p className="text-center">No transaction found.</p>
          )}
        </Card>
      </Container>
    </div>
  );
};

export default TransactionPage;
