import React, { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  Form,
  Modal,
} from "react-bootstrap";
import backButtonImg from "../assets/backbutton.png";
import homeButtonImg from "../assets/home-button.png";
import axios from "axios";
import { API_BASE_URL } from "../apiConfig";

const AdminManageClientAccPage = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [headers,setHeaders] = useState(null);
  const givenName = localStorage.getItem("givenName");
  const familyName = localStorage.getItem("familyName");
  const userId = localStorage.getItem("userId");
  const { getAuthHeaders } = useAuthContext();

  useEffect(() => {
    const fetchClientsAndVerifications = async () => {
      try {
        const headers = await getAuthHeaders();
        setHeaders(headers)
        const [clientResponse, verificationResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/clients`, { headers }),
          axios.get(`${API_BASE_URL}/verification`, { headers }),
        ]);

        const clients = clientResponse.data;
        const verifications = verificationResponse.data;

        const clientsWithVerificationStatus = clients.map((client) => {
          const verification = verifications.find(
            (v) => v.client_id === client.client_id
          );
          return {
            ...client,
            verification_status: verification
              ? verification.verification_status
              : "Unknown",
          };
        });

        setClients(clientsWithVerificationStatus);
      } catch (error) {
        console.error("Error fetching clients and verifications:", error);
      }
    };

    fetchClientsAndVerifications();
  }, [getAuthHeaders]);

  const filteredClients = clients.filter((client) =>
    `${client.first_name} ${client.last_name}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handleSearch = (e) => setSearchTerm(e.target.value);

  const handleCreateAccountClick = () => navigate("/create-client-profile");

  const handleEditClick = (client) => {
    axios
      .get(`${API_BASE_URL}/clients/${client.client_id}?agentname=${givenName} ${familyName}&agentid=${userId}`, { headers })
      .then(() => {
        navigate(`/update-client-profile`, { state: { clientData: client } });
      })
      .catch((error) => {
        console.error("Axios request failed:", error);
      });
  };

  const handleDeleteClick = (client) => {
    setSelectedClient(client);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const headers = await getAuthHeaders();

      const requestData = {
        deleted_by_id: userId,
        deleted_by_name: `${familyName} ${givenName}`,
      };

      const response = await axios.put(
        `${API_BASE_URL}/clients/delete/${selectedClient.client_id}`,
        requestData,
        {
          headers: {
            ...headers,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setClients(
          clients.filter(
            (client) => client.client_id !== selectedClient.client_id
          )
        );
        setShowDeleteModal(false);
      } else {
        alert("Error deleting profile.");
      }
    } catch (error) {
      alert("Error deleting profile.");
    }
  };

  // Separate functions for closing each modal
  const handleClose = () => setShowModal(false);
  const handleCancelDelete = () => setShowDeleteModal(false);

  const handleStatusClick = (client) => {
    const status = client.verification_status;
    if (status === "Verified") {
      navigate("/view-verified-client-details", { state: { client } });
    } else if (status === "Pending") {
      navigate("/list-of-clients-for-approval");
    } else if (status === "Rejected" || status === "Not Verified") {
      setSelectedClientId(client.client_id);
      setShowModal(true);
    }
  };

  const handleSendEmail = async () => {
    if (!selectedClientId) return;
    setLoadingEmail(true);
    try {
      const headers = await getAuthHeaders();

      const response = await axios.post(
        `${API_BASE_URL}/verification/email/${selectedClientId}`,
        {},
        { headers }
      );
      if (response.status === 200) {
        alert(`Email sent to the client successfully.`);
      } else {
        alert(`Failed to send email. Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email to the client. Please try again.");
    } finally {
      setLoadingEmail(false);
      setShowModal(false);
    }
  };
 
  return (
    <div
      style={{
        minHeight: "100vh",
        padding: "5px",
        background:
          "linear-gradient(270deg, rgba(12,118,245,1) 0%, rgba(40,137,255,0.9304096638655462) 96%)",
      }}
    >
      <Container fluid className="pt-3 pb-3 px-3">
        <Row className="mb-3 align-items-center">
          <Col xs={2} className="text-start">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate(-1)}
            >
              <img
                src={backButtonImg}
                alt="Back"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Button>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="search"
              placeholder="Search Client Name"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Col>
          <Col xs={2} className="text-end">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate("/")}
            >
              <img
                src={homeButtonImg}
                alt="Home"
                style={{
                  width: "75%",
                  height: "75%",
                  objectFit: "cover",
                  borderRadius: "0%",
                }}
              />
            </Button>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={4}>
            <Button
              variant="light"
              className="w-100 rounded-4"
              style={{
                backgroundColor: "#FFFFFF",
                color: "#817F7F",
                border: "none",
              }}
              onClick={() => navigate("/admin-manage-admin-accounts")}
            >
              Admin
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              variant="light"
              className="w-100 rounded-4"
              style={{
                backgroundColor: "#FFFFFF",
                color: "#817F7F",
                border: "none",
              }}
              onClick={() => navigate("/admin-manage-agent-accounts")}
            >
              Agents
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              variant="light"
              className="w-100 rounded-4"
              style={{ backgroundColor: "#CFE3FD" }}
            >
              Clients
            </Button>
          </Col>
        </Row>

        <Card
          className="mb-3"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <h4 className="mb-3 text-white" style={{ textAlign: "center" }}>
            CLIENT PROFILE LIST
          </h4>

          {filteredClients.map((client, index) => {
            const status = client.verification_status;
            return (
              <Card
                key={index}
                className="mb-3"
                style={{
                  borderRadius: "15px",
                  background:
                    " linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
                  border: "none",
                }}
              >
                <Card.Body>
                  <Row className="align-items-center">
                    <Col>
                      <div className="d-flex flex-column flex-md-row align-items-md-center">
                        <p className="mb-0 me-md-5">
                          {client.first_name} {client.last_name}
                        </p>
                        <p className="mb-0">Client ID: {client.client_id}</p>
                      </div>
                      <p
                        onClick={() => handleStatusClick(client)}
                        className="mb-0 small"
                        style={{ color: "#0D75F4", cursor: "pointer" }}
                      >
                        Status: {status}
                      </p>
                      {status !== "Rejected" &&
                        status !== "Not Verified" &&
                        status !== "Pending" && (
                          <>
                            <p
                              onClick={() =>
                                navigate(`/linked-accounts/${client.client_id}`)
                              }
                              className="small"
                              style={{
                                marginTop: "10px",
                                marginBottom: "3px",
                                color: "rgb(18, 94, 171)",
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            >
                              View Linked Accounts
                            </p>
                            <p
                              onClick={() =>
                                navigate(`/transactions/${client.client_id}`)
                              }
                              className="small"
                              style={{
                                marginTop: "0px",
                                marginBottom: "3px",
                                color: "rgb(18, 94, 171)",
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            >
                              View Transactions
                            </p>
                          </>
                        )}
                      
                    </Col>
                    <Col
                      xs={2}
                      className="d-none d-md-flex flex-column align-items-end"
                    >
                      <Button
                        variant="outline-primary"
                        className="mb-2 rounded-4"
                        style={{ width: "100%" }}
                        onClick={() => handleEditClick(client)}
                      >
                        View/Edit Client
                      </Button>
                      <Button
                        variant="outline-danger"
                        className="mb-2 rounded-4"
                        style={{ width: "100%" }}
                        onClick={() => handleDeleteClick(client)}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>

                  {/* Small screen buttons */}
                  <Row className="d-md-none mt-3">
                    <Col className="d-flex justify-content-between">
                      <Button
                        variant="outline-primary"
                        className="me-2 rounded-4"
                        style={{ flex: "1" }}
                        onClick={() => handleEditClick(client)}
                      >
                        View / Edit
                      </Button>
                      <Button
                        variant="outline-danger"
                        className="rounded-4"
                        style={{ flex: "1" }}
                        onClick={() => handleDeleteClick(client)}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          })}

          <Button
            variant="light"
            className="mb-3 mt-2 p-3 w-100 rounded-4"
            onClick={handleCreateAccountClick}
          >
            CREATE NEW CLIENT
          </Button>
        </Card>
      </Container>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>SEND EMAIL TO CLIENT?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to trigger an email to the client?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSendEmail}
            disabled={loadingEmail}
          >
            {loadingEmail ? "Sending..." : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCancelDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>DELETE PROFILE?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete this Client Profile? All linked
            accounts will be deleted. You can’t undo this action.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminManageClientAccPage;
