import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";

const ClientVerification = () => {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState(null);
  
    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        if (isValidFileType(selectedFile)) {
          setFile(selectedFile);
          setMessage({ type: "success", text: "File selected successfully." });
        } else {
          setFile(null);
          setMessage({ type: "error", text: "Please select an image or PDF file." });
          e.target.value = null;
        }
      }
    };    

    const isValidFileType = (file) => {
      const acceptedTypes = ["image/jpeg", "image/png", "application/pdf"];
      return acceptedTypes.includes(file.type);
    };
    
    const handleSubmit = (e) => {
      e.preventDefault();
      if (file) {
        setMessage(
          { 
            type: "success", 
            text: "File uploaded successfully!" 
          }
        );

      } else {
        setMessage(
          { 
            type: "error", 
            text: "Please select a file before uploading." 
          }
        );
      }
    };
  
    return (
      <div
        style={{
          minHeight: "100vh",
          padding: "5px",
          background:
            "linear-gradient(270deg, rgba(12,118,245,1) 0%, rgba(40,137,255,0.9304096638655462) 96%)",
        }}
      >
        <div className="row justify-content-center">
          <div className="col-md-6">
            <h4 className="mb-3 mt-4 text-white" style={{ textAlign: "center" }}>
              Please Upload Your NRIC
            </h4>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="fileInput" className="form-label">
                      Select Image 
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="fileInput"
                      onChange={handleFileChange}
                      accept="image/*,.pdf"
                    />
                  </div>
                  <Button 
                    variant="outline-primary"
                    type="submit" 
                    className="mb-2 rounded-4"
                    style={{ width: "100% "}}
                  >
                    Upload File
                  </Button>
                </form>
                {message && (
                  <div 
                    className={`alert ${message.type === "error" ? "alert-danger" : "alert-success"} mt-3`} 
                    role="alert"
                  >                
                    <strong>{message.type === "error" ? "Error: " : "Success: "}</strong>
                    {message.text}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default ClientVerification;