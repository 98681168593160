import React, { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  Form, 
  Button, 
  Image, 
  Modal 
} from "react-bootstrap";
import axios from "axios";
import backButtonImg from "../assets/backbutton.png";
 
const ApproveClientVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [clientDetails, setClientDetails] = useState(null);
  const [s3Url, setS3Url] = useState(null);
  const [verificationId, setVerificationId] = useState(null);

  const client = location.state?.client;

  const userId = localStorage.getItem("userId") || "N/A";
  const givenName = localStorage.getItem("givenName") || "N/A";
  const familyName = localStorage.getItem("familyName") || "N/A";
  const modifiedByName = `${givenName} ${familyName}`;
  
  const { getAuthHeaders } = useAuthContext();

  useEffect(() => {
    if (client) {
      const fetchClientDetails = async () => {
        try {
          const headers = await getAuthHeaders();

          const clientDetailsUrl = `https://api.itsag1t4.com/clients/${client.client_id}?agentname=${modifiedByName}&agentid=${userId}`;
          const response = await axios.get(clientDetailsUrl, { headers });
          setClientDetails(response.data);
        } catch (error) {
          console.error("Error fetching client details:", error);
        }
      };

      const fetchVerificationDetails = async () => {
        try {
          const headers = await getAuthHeaders();

          const verificationDetailsUrl = `https://api.itsag1t4.com/verification`;
          const response = await axios.get(verificationDetailsUrl, { headers });

          // Find the verification record that matches the client ID
          const verification = response.data.find(v => v.client_id === client.client_id);

          if (verification) {
            setVerificationId(verification.verification_id);
            setS3Url(verification.s3_url);
          } else {
            console.error("No verification details found for this client");
          }
        } catch (error) {
          console.error("Error fetching verification details:", error);
        }
      };

      fetchClientDetails();
      fetchVerificationDetails();
    }
  }, [client, getAuthHeaders]);

  if (!client) {
    return <div>Client not found</div>;
  }

  if (!clientDetails || !verificationId || !s3Url) {
    return <div>Loading client details...</div>;
  }

  const handleVerify = () => setShowVerifyModal(true);
  const handleDecline = () => setShowDeclineModal(true);

  const handleConfirmVerify = async (e) => {
    e.preventDefault();
    const requestData = {
      verification_status: "Verified",
      modified_by_id: userId,
      modified_by_name: modifiedByName
    };


    try {
      const headers = await getAuthHeaders();

      const response = await axios.patch(`https://api.itsag1t4.com/verification/${verificationId}`, requestData, {
        headers: { 
          ...headers,
          "Content-Type": "application/json" 
        },
      });

      setShowVerifyModal(false);
      navigate("/list-of-clients-for-approval", { state: { refresh: true } });
    } catch (error) {
      console.error("Error verifying client:", error);
      alert("Failed to verify client. Please try again.");
    }
  };

  const handleConfirmDecline = async () => {
    const requestData = {
      verification_status: "Rejected",
      modified_by_id: userId,
      modified_by_name: modifiedByName
    };

 

    try {
      const headers = await getAuthHeaders();
      
      const response = await axios.patch(`https://api.itsag1t4.com/verification/${verificationId}`, requestData, {
        headers: { 
          ...headers,
          "Content-Type": "application/json" 
        },
      });

      setShowDeclineModal(false);
      navigate("/list-of-clients-for-approval", { state: { refresh: true } });
    } catch (error) {
      console.error("Error declining verification:", error);
      alert("Failed to decline verification. Please try again.");
    }
  };

  const handleCancelVerify = () => setShowVerifyModal(false);
  const handleCancelDecline = () => setShowDeclineModal(false);

  return (
    <div 
      style={{ 
        minHeight: "100vh", 
        padding: "5px", 
        backgroundColor: "#DBEAFD" 
      }}
    >
      <Container className="mt-2 p-2">
        <div className="d-flex align-items-center mb-4">
          <Button
            variant="light"
            className="p-0"
            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
            onClick={() => navigate(-1)}
          >
            <img 
              src={backButtonImg} 
              alt="Back" 
              style={{ 
                width: "100%", 
                height: "100%", 
                objectFit: "cover", 
                borderRadius: "50%" 
              }}
            />
          </Button>
          <h4 className="m-4 text-center mx-auto">
            <strong>Verify Client: {clientDetails.first_name} {clientDetails.last_name}</strong>
          </h4>
        </div>

        <Row>
          <Col md={6}>
            <Card className="mb-4 rounded-4">
              <Card.Header as="h6">NRIC</Card.Header>
              <Card.Body>
                {s3Url ? (
                  <Image src={s3Url} alt="NRIC" fluid />
                ) : (
                  <p>No NRIC image available.</p>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-4">
              <Card.Header as="h6">Client Details</Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={`${clientDetails.first_name} ${clientDetails.last_name}`}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={clientDetails.address}
                      readOnly
                    />
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="d-flex justify-content-center">
          <Button 
            variant="success" 
            className="me-2 rounded-4" 
            onClick={handleVerify}
          >
            Confirm Verification
          </Button>
          <Button variant="danger" className="me-2 rounded-4" onClick={handleDecline}>
            Decline Verification
          </Button>
          </Col>
        </Row>

        {/* Verification Confirmation Modal */}
        <Modal show={showVerifyModal} onHide={handleCancelVerify} centered>
          <Modal.Header closeButton>
            <Modal.Title>VERIFY CLIENT?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to verify this client profile? This action will mark the client as verified.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancelVerify}>Cancel</Button>
            <Button variant="success" onClick={handleConfirmVerify}>Confirm</Button>
          </Modal.Footer>
        </Modal>

        {/* Decline Confirmation Modal */}
        <Modal show={showDeclineModal} onHide={handleCancelDecline} centered>
          <Modal.Header closeButton>
            <Modal.Title>DECLINE VERIFICATION?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to decline the verification for this client? This action cannot be undone.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              variant="secondary" 
              onClick={
                handleCancelDecline
              }
            >
              Cancel
            </Button>
            <Button 
              variant="danger" 
              onClick={
                handleConfirmDecline
              }
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default ApproveClientVerification;
