import React, { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { Form, Button, Container, Row, Col, Modal } from "react-bootstrap";
import profileIcon from "../assets/profileIcon.png";
import { useNavigate } from "react-router-dom";
import backButtonImg from "../assets/backbutton.png";
import axios from "axios";
import { API_BASE_URL } from "../apiConfig";
import AWS from "aws-sdk";

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.Credentials(
    process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  ),
});

const cognito = new AWS.CognitoIdentityServiceProvider();

const CreateClientProfile = ({ user }) => {
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const givenName = localStorage.getItem("givenName") || "N/A";
  const familyName = localStorage.getItem("familyName") || "N/A";

  const userDetails = {
    userId: user?.userId || "N/A",
    email: user?.username || "N/A",
    givenName,
    familyName,
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    email_address: "",
    phone_number: "",
    address: "",
    city: "",
    country: "",
    postal_code: "",
    created_by_id: userDetails.userId,
    created_by_name: `${userDetails.givenName} ${userDetails.familyName}`.trim(),
  });

  const [agentId, setAgentId] = useState(userDetails.agentId);
  const [isAgent, setIsAgent] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [agentsList, setAgentsList] = useState([]);

  const [errors, setErrors] = useState({});
  const { getAuthHeaders } = useAuthContext();

  const errorStyle = {
    border: "1px solid red",
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "first_name":
      case "last_name":
        if (!value) {
          error = "This field is required.";
        } else if (value.length < 2 || value.length > 50) {
          error = "Must be between 2 and 50 characters.";
        } else if (!/^[A-Za-z\s]+$/.test(value)) {
          error = "Must contain only alphabetic characters and spaces.";
        }
        break;
      case "date_of_birth":
        const date = new Date(value);
        const age = new Date().getFullYear() - date.getFullYear();
        if (!value) {
          error = "This field is required.";
        } else if (date >= new Date()) {
          error = "Date must be in the past.";
        } else if (age < 18 || age > 100) {
          error = "Age must be between 18 and 100 years.";
        }
        break;
      case "gender":
        const allowedGenders = [
          "Male",
          "Female",
          "Non-binary",
          "Prefer not to say",
        ];
        if (!value || !allowedGenders.includes(value)) {
          error = "Must be one of the predefined options.";
        }
        break;
      case "email_address":
        if (!value) {
          error = "This field is required.";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "Invalid email format.";
        }
        break;
      case "phone_number":
        if (!value) {
          error = "This field is required.";
        } else if (!/^\+\d{10,15}$/.test(value)) {
          error = "Must be a valid phone number (e.g., +1234567890).";
        }
        break;
      case "address":
        if (!value) {
          error = "This field is required.";
        } else if (value.length < 5 || value.length > 100) {
          error = "Must be between 5 and 100 characters.";
        }
        break;
      case "city":
      case "state":
      case "country":
        if (!value) {
          error = "This field is required.";
        } else if (value.length < 2 || value.length > 50) {
          error = "Must be between 2 and 50 characters.";
        }
        break;
      case "postal_code":
        if (!value) {
          error = "This field is required.";
        } else if (!/^\d{6}$/.test(value)) {
          error = "Enter a valid postal code of 6 digits.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  useEffect(() => {
    if (user && (user.givenName || user.familyName)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        created_by_id: user.userId || "N/A",
        created_by_name: `${userDetails.givenName} ${userDetails.familyName}`.trim(),
      }));
    }
  }, [user]);

  const fetchAgentId = async () => {
    try {
      const headers = await getAuthHeaders();
      const username = localStorage.getItem("username");
      if (!username) {
        console.error("No username found in localStorage");
        return;
      }

      const params = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        Username: username,
      };

      const groupData = await cognito.adminListGroupsForUser(params).promise();
      const groups = groupData.Groups.map((group) => group.GroupName);

      if (groups.includes("agents")) {
        setIsAgent(true);
        const response = await cognito.adminGetUser(params).promise();
        const userId =
          response.UserAttributes.find((attr) => attr.Name === "sub")?.Value ||
          "No sub";
        setAgentId(userId);
      } else if (groups.includes("root_admin")) {
        setIsAdmin(true);
        fetchAgentsList(headers);
      } else {
        setIsAgent(false);
        setIsAdmin(false);
        setAgentId("");
      }
    } catch (error) {
      console.error("Error fetching user groups or ID:", error);
    }
  };

  const fetchAgentsList = async (headers) => {
    try {
      const params = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        GroupName: "agents",
      };

      const agentData = await cognito.listUsersInGroup(params).promise();

      const agents = agentData.Users.map((user) => {
        const userId =
          user.Attributes.find((attr) => attr.Name === "sub")?.Value || "No ID";
        const firstName =
          user.Attributes.find((attr) => attr.Name === "given_name")?.Value ||
          "No Name";
        return { id: userId, first_name: firstName };
      });

      setAgentsList(agents);
    } catch (error) {
      console.error("Error fetching agents list:", error);
    }
  };

  useEffect(() => {
    fetchAgentId();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const error = validateField(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    Object.keys(formData).forEach((field) => {
      const error = validateField(field, formData[field]);
      if (error) validationErrors[field] = error;
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      alert("Please fix the errors before submitting.");
      return;
    }

    try {
      const headers = await getAuthHeaders();

      const requestData = {
        ...formData,
        agent_id: agentId,
        created_by_id: formData.created_by_id,
        created_by_name: formData.created_by_name,
      };

      const response = await axios.post(
        `${API_BASE_URL}/clients`,
        requestData,
        {
          headers: {
            ...headers,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        setShowSuccessModal(true);
      } else {
        alert("Failed to create client profile.");
      }
    } catch (error) {
      // Check for specific errors related to email or phone duplication
      if (error.response && error.response.status === 500) {
        const errorMessage = error.response.data.message || "";
        if (errorMessage.includes("email_address")) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email_address: "Email address is already used. Please try again.",
          }));
        } else if (errorMessage.includes("phone_number")) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            phone_number: "Phone number is already used. Please try again.",
          }));
        } else {
          alert(
            "Phone number / email address is already in use. Please try again."
          ); // Generic message for other cases
        }
      } else {
        alert("An error occurred while creating the client profile.");
      }
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate("/");
  };

  return (
    <div
      style={{ backgroundColor: "#DBEAFD", minHeight: "100vh", padding: "5px" }}
    >
      <Container fluid className="pt-3 pb-3 px-3">
        <Row className="mb-3 align-items-center">
          <Col xs={2} className="text-start">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate(-1)}
            >
              <img
                src={backButtonImg}
                alt="Back"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Button>
          </Col>
        </Row>
        <Row className="mb-4 text-center">
          <Col>
            <img
              src={profileIcon}
              alt="Profile Icon"
              className="img-fluid"
              style={{ maxWidth: "60px" }}
            />
            <h4 className="mt-3">CLIENT PROFILE</h4>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formFirstName" className="mb-3">
            <Form.Control
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
              placeholder="First Name"
              style={{
                height: "50px",
                ...(errors.first_name ? errorStyle : {}),
              }}
            />
            {errors.first_name && (
              <p style={{ color: "red" }}>{errors.first_name}</p>
            )}
          </Form.Group>
          <Form.Group controlId="formLastName" className="mb-3">
            <Form.Control
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
              placeholder="Last Name"
              style={{
                height: "50px",
                ...(errors.last_name ? errorStyle : {}),
              }}
            />
            {errors.last_name && (
              <p style={{ color: "red" }}>{errors.last_name}</p>
            )}
          </Form.Group>
          <Form.Group controlId="formDOB" className="mb-3">
            <Form.Control
              type="date"
              name="date_of_birth"
              value={formData.date_of_birth}
              onChange={handleInputChange}
              placeholder="Date of Birth"
              style={{
                ...(errors.date_of_birth ? errorStyle : {}),
                height: "50px",
              }}
            />
            {errors.date_of_birth && (
              <p style={{ color: "red" }}>{errors.date_of_birth}</p>
            )}
          </Form.Group>
          <Form.Group controlId="formGender" className="mb-3">
            <Form.Control
              as="select"
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              style={{
                height: "50px",
                ...(errors.gender ? errorStyle : {}),
              }}
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Non-binary">Non-binary</option>
              <option value="Prefer not to say">Prefer not to say</option>
            </Form.Control>
            {errors.gender && <p style={{ color: "red" }}>{errors.gender}</p>}
          </Form.Group>
          <Form.Group controlId="formEmail" className="mb-3">
            <Form.Control
              type="email"
              name="email_address"
              value={formData.email_address}
              onChange={handleInputChange}
              placeholder="Email"
              style={{
                ...(errors.email_address ? errorStyle : {}),
                height: "50px",
              }}
            />
            {errors.email_address && (
              <p style={{ color: "red" }}>{errors.email_address}</p>
            )}
          </Form.Group>
          <Form.Group controlId="formPhone" className="mb-3">
            <Form.Control
              type="text"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleInputChange}
              placeholder="Phone"
              style={{
                ...(errors.phone_number ? errorStyle : {}),
                height: "50px",
              }}
            />
            {errors.phone_number && (
              <p style={{ color: "red" }}>{errors.phone_number}</p>
            )}
          </Form.Group>
          <Form.Group controlId="formAddress" className="mb-3">
            <Form.Control
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              placeholder="Address"
              style={{
                height: "50px",
                ...(errors.address ? errorStyle : {}),
              }}
            />
            {errors.address && <p style={{ color: "red" }}>{errors.address}</p>}
          </Form.Group>
          <Form.Group controlId="formCity" className="mb-3">
            <Form.Control
              type="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              placeholder="City"
              style={{
                height: "50px",
                ...(errors.city ? errorStyle : {}),
              }}
            />
            {errors.city && <p style={{ color: "red" }}>{errors.city}</p>}
          </Form.Group>
          <Form.Group controlId="formState" className="mb-3">
            <Form.Control
              type="text"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              placeholder="State"
              style={{
                height: "50px",
                ...(errors.state ? errorStyle : {}),
              }}
            />
            {errors.state && <p style={{ color: "red" }}>{errors.state}</p>}
          </Form.Group>
          <Form.Group controlId="formCountry" className="mb-3">
            <Form.Control
              type="text"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              placeholder="Country"
              style={{
                height: "50px",
                ...(errors.country ? errorStyle : {}),
              }}
            />
            {errors.country && <p style={{ color: "red" }}>{errors.country}</p>}
          </Form.Group>
          <Form.Group controlId="formPostalCode" className="mb-3">
            <Form.Control
              type="text"
              name="postal_code"
              value={formData.postal_code}
              onChange={handleInputChange}
              placeholder="Postal Code"
              style={{
                height: "50px",
                ...(errors.postal_code ? errorStyle : {}),
              }}
            />
            {errors.postal_code && (
              <p style={{ color: "red" }}>{errors.postal_code}</p>
            )}
          </Form.Group>
          <Form.Group controlId="formAgentId" className="mb-3">
            {isAdmin ? (
              <>
                {agentsList.length > 0 && (
                  <Form.Control
                    as="select"
                    value={agentId}
                    onChange={(e) => setAgentId(e.target.value)}
                    className="mb-2"
                    style={{ height: "50px" }}
                  >
                    <option value="">Select Agent</option>
                    {agentsList.map((agent) => (
                      <option key={agent.id} value={agent.id}>
                        {agent.first_name} (ID: {agent.id})
                      </option>
                    ))}
                  </Form.Control>
                )}
              </>
            ) : (
              <Form.Control
                type="text"
                name="agentId"
                value={agentId}
                style={{ height: "50px" }}
                readOnly
                placeholder="Agent ID In Charge"
              />
            )}
          </Form.Group>
          <div style={{ textAlign: "left" }}>
            <strong>Created by (name):</strong> {formData.created_by_name}
          </div>
          <div style={{ textAlign: "left" }}>
            <strong>Created by (userId):</strong> {formData.created_by_id}
          </div>
          <Row className="mt-4">
            <Col xs={12} md={6}>
              <Button
                variant="primary"
                type="submit"
                className="w-100"
                style={{ height: "50px" }}
              >
                Create
              </Button>
            </Col>
            <Col xs={12} md={6} style={{ marginTop: "10px" }}>
              <Button
                variant="outline-secondary"
                type="button"
                className="w-100"
                onClick={() => navigate(-1)}
                style={{ height: "50px" }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>

        <Modal show={showSuccessModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>Client profile has been successfully created!</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseModal}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default CreateClientProfile;
