import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import profileIcon from "../assets/profileIcon.png";
import { useNavigate, useLocation } from "react-router-dom";
import backButtonImg from "../assets/backbutton.png";

import AWS from "aws-sdk";
import { fetchAuthSession } from "aws-amplify/auth";

const AdminUpdateAgentPage = () => {
  const location = useLocation(); // Retrieve the email from state
  const navigate = useNavigate();

  // Get the email passed from AdminManageAgentAccPage
  const { email } = location.state || {};

  const [agentData, setAgentData] = useState({
    firstName: "",
    lastName: "",
    email: email || "agent.email@example.com", // Placeholder email for fetching the profile
  });

  const [idToken, setIdToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [fetchError, setFetchError] = useState(null);

  const fetchUserAttributes = async () => {
    setLoading(true);
    try {
      const userSession = await fetchAuthSession();
      const idToken = userSession.tokens?.idToken?.toString();
      setIdToken(idToken);

      AWS.config.region = process.env.REACT_APP_AWS_REGION;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        RoleArn:
          process.env.REACT_APP_AUTH_ROLE,
        Logins: {
          [process.env.REACT_APP_AUTH_LOGIN]:
            idToken,
        },
      });

      const cognitoidentityserviceprovider =
        new AWS.CognitoIdentityServiceProvider();

      const getUserParams = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        Username: agentData.email,
      };

      cognitoidentityserviceprovider.adminGetUser(
        getUserParams,
        function (err, data) {
          if (err) {
            console.error("Error fetching agent data:", err);
            setFetchError("Failed to retrieve agent data.");
          } else {
            const userAttributes = {};
            data.UserAttributes.forEach((attribute) => {
              if (attribute.Name === "given_name") {
                userAttributes.firstName = attribute.Value;
              }
              if (attribute.Name === "family_name") {
                userAttributes.lastName = attribute.Value;
              }
              if (attribute.Name === "email") {
                userAttributes.email = attribute.Value;
              }
            });

            setAgentData(userAttributes);
          }
        }
      );
    } catch (error) {
      console.error("Error during fetch:", error);
      setFetchError("An error occurred during the fetch. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAgentData({
      ...agentData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      AWS.config.region = process.env.REACT_APP_AWS_REGION;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        RoleArn:
          process.env.REACT_APP_AUTH_ROLE,
        Logins: {
          [process.env.REACT_APP_AUTH_LOGIN]:
            idToken,
        },
      });

      const cognitoidentityserviceprovider =
        new AWS.CognitoIdentityServiceProvider();

      const updateParams = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        Username: agentData.email,
        UserAttributes: [
          { Name: "given_name", Value: agentData.firstName },
          { Name: "family_name", Value: agentData.lastName },
          { Name: "email", Value: agentData.email },
          { Name: "email_verified", Value: "true" },
        ],
      };

      cognitoidentityserviceprovider.adminUpdateUserAttributes(
        updateParams,
        function (err, data) {
          if (err) {
            console.error("Error updating agent profile:", err);
            setUpdateError("Failed to update agent profile.");
            alert("Failed to update agent profile. Please try again.");
          } else {
            alert("Agent profile updated successfully!");
            navigate(-1);
          }
        }
      );
    } catch (error) {
      console.error("Error during agent profile update:", error);
      setUpdateError("An error occurred during the update.");
      alert("An error occurred during the update. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#DBEAFD",
        borderRadius: "10px",
        minHeight: "100vh",
        padding: "5px",
      }}
    >
      <Container fluid className="pt-3 pb-3 px-3">
        <Row className="mb-3 align-items-center">
          <Col xs={2}>
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate(-1)}
            >
              <img
                src={backButtonImg}
                alt="Back"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Button>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xl={8} className="">
            <Row className="mb-4 text-center">
              <Col>
                <img
                  src={profileIcon}
                  alt="Profile Icon"
                  className="img-fluid"
                  style={{ maxWidth: "60px" }}
                />
                <h4 className="mt-3">AGENT PROFILE</h4>
              </Col>
            </Row>
            {fetchError && <p style={{ color: "red" }}>{fetchError}</p>}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formFirstName" className="mb-3">
                <Form.Control
                  type="text"
                  name="firstName"
                  value={agentData.firstName}
                  placeholder="First Name"
                  onChange={handleInputChange}
                  disabled={loading}
                  style={{ height: "50px" }}
                />
              </Form.Group>
              <Form.Group controlId="formLastName" className="mb-3">
                <Form.Control
                  type="text"
                  name="lastName"
                  value={agentData.lastName}
                  placeholder="Last Name"
                  onChange={handleInputChange}
                  disabled={loading}
                  style={{ height: "50px" }}
                />
              </Form.Group>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Control
                  type="email"
                  name="email"
                  value={agentData.email}
                  placeholder="Email"
                  onChange={handleInputChange}
                  disabled
                  style={{ height: "50px" }}
                />
              </Form.Group>
              <Row className="mt-4">
                <Col xs={12} md={6}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    disabled={loading}
                    style={{ height: "50px" }}
                  >
                    {loading ? "Updating..." : "Update"}
                  </Button>
                </Col>
                <Col xs={12} md={6} style={{ marginTop: "10px" }}>
                  <Button
                    variant="outline-secondary"
                    type="button"
                    className="w-100"
                    onClick={() => navigate(-1)}
                    disabled={loading}
                    style={{ height: "50px" }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
            {updateError && <p style={{ color: "red" }}>{updateError}</p>}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminUpdateAgentPage;
