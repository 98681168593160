import React, { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  InputGroup,
  FormControl,
  Badge,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import searchIcon from "../assets/searchicon.png";
import dollarIcon from "../assets/dollarCoin.png";
import backButtonImg from "../assets/backbutton.png";
import homeButtonImg from "../assets/home-button.png";
import axios from "axios";
import { API_BASE_URL } from "../apiConfig";

const AllTransacPage = ({ user, userGroup }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { getAuthHeaders } = useAuthContext();
  const agentId = user?.userId || null;

  useEffect(() => {
    const fetchAllTransactions = async () => {
      try {
        const headers = await getAuthHeaders();

        let allTransactions;

        if (userGroup === "root_admin") {
          // Fetch all transactions for admin
          const response = await axios.get(`${API_BASE_URL}/transactions`, {
            headers,
          });
          allTransactions = response.data;
        } else if (userGroup === "agents" && agentId) {
          // Fetch transactions filtered for the agent
          const response = await axios.get(`${API_BASE_URL}/transactions`, {
            headers,
          });
          allTransactions = response.data;

          // Fetch client data and filter transactions based on agent_id
          const clientResponse = await axios.get(`${API_BASE_URL}/clients`, {
            headers,
          });
          const clients = clientResponse.data;

          // Filter clients based on the agent_id
          const clientIdsForAgent = clients
            .filter((client) => client.agent_id === agentId)
            .map((client) => client.client_id);

          // Filter transactions to only include those for the clients under the agent
          allTransactions = allTransactions.filter((transaction) =>
            clientIdsForAgent.includes(transaction.client_id)
          );
        }

        setTransactions(allTransactions);
      } catch (error) {
        console.error("Error fetching transactions or clients:", error);
        setError("Failed to load transactions.");
      } finally {
        setLoading(false);
      }
    };

    if (
      userGroup &&
      (userGroup === "root_admin" || (userGroup === "agents" && agentId))
    ) {
      fetchAllTransactions();
    }
  }, [agentId, userGroup, getAuthHeaders]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const filteredTransactions = transactions.filter((transaction) =>
    transaction.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleViewClientTransactions = (client_id) => {
    navigate(`/transactions/${client_id}`);
  };

  const renderTransaction = (transaction) => (
    <Row className="mb-3 p-1 pb-1" key={transaction.transaction_id}>
      <Col xs={12}>
        <Card
          className="p-3"
          style={{
            background:
              " linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
            borderRadius: "15px",
            border: "none",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Row className="w-100 d-flex align-items-center text-start  justify-content-between">
            <Col xs={6}>
              <p className="mb-1 small">
                <strong>Transaction ID:</strong> {transaction.transaction_id}
              </p>
              <p className="mb-1 small">
                <strong>Client ID:</strong> {transaction.client_id}
              </p>
            </Col>
            <Col xs={3} className="text-end" style={{ marginRight: "40px" }}>
              <Badge
                bg={
                  transaction.status === "Completed"
                    ? "success"
                    : transaction.status === "Failed"
                    ? "danger"
                    : "warning"
                }
                pill
              >
                {transaction.status}
              </Badge>
            </Col>
          </Row>

          <Row className="w-100 d-flex align-items-center justify-content-between mt-2">
            <Col xs={3}>
              <div className="d-flex align-items-center">
                <img
                  src={dollarIcon}
                  alt="Dollar Icon"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "8px",
                  }}
                />
                <h5 className="mb-0 small">${transaction.amount.toFixed(2)}</h5>
              </div>
            </Col>
            <Col xs={7} className="text-end">
              <Button
                variant="link"
                className="p-0 small"
                style={{
                  textDecoration: "none",
                  color: "#125eab",
                  fontSize: "0.75rem",
                }}
                onClick={() =>
                  handleViewClientTransactions(transaction.client_id)
                }
              >
                <strong>View Client Transactions</strong>
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        padding: "5px",
        background:
          "linear-gradient(270deg, rgba(12,118,245,1) 0%, rgba(40,137,255,0.9304096638655462) 96%)",
      }}
    >
      <Container fluid className="flex-grow-1 d-flex flex-column pt-3 pb-3">
        {/* Top Bar: Back Button, Search, and Home Button */}
        <Row className="mb-3 align-items-center">
          <Col xs={2} className="text-start">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate(-1)}
            >
              <img
                src={backButtonImg}
                alt="Back"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Button>
          </Col>
          <Col xs={8} className="rounded-2 align-items-center">
            <InputGroup>
              <InputGroup.Text className="bg-white">
                <img
                  src={searchIcon}
                  alt="Search"
                  style={{ width: "20px", height: "20px" }}
                />
              </InputGroup.Text>
              <FormControl
                placeholder="Search by transaction status"
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={searchTerm}
                onChange={handleSearch}
              />
            </InputGroup>
          </Col>
          <Col xs={2} className="text-end">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate("/")}
            >
              <img
                src={homeButtonImg}
                alt="Home"
                style={{
                  width: "75%",
                  height: "75%",
                  objectFit: "cover",
                  borderRadius: "0%",
                }}
              />
            </Button>
          </Col>
        </Row>

        {/* Transactions Card with maxHeight */}
        <Card
          style={{
            background: "transparent",
            borderRadius: "15px",
            border: "none",
            maxHeight: "700px",
            marginTop: "20px",
          }}
        >
          <h5 className="text-center text-white mb-3">
            <strong>ALL TRANSACTIONS</strong>
          </h5>

          {filteredTransactions.map(renderTransaction)}

          {filteredTransactions.length === 0 && (
            <p className="text-center">
              No transactions found with the given status.
            </p>
          )}
        </Card>
      </Container>
    </div>
  );
};

export default AllTransacPage;
