import React, { useState } from "react";
import { 
  Form, 
  Button, 
  Container, 
  Row, 
  Col 
} from "react-bootstrap";
import lockIcon from "../assets/resetpassword.png"; 
import { confirmResetPassword } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";  // Import useNavigate

const ResetPassword = () => {
  const [username, setUsername] = useState(""); // For username input
  const [confirmationCode, setConfirmationCode] = useState(""); // For confirmation code input
  const [newPassword, setNewPassword] = useState(""); // For new password input
  const navigate = useNavigate();  // Initialize navigate

  // Handles the confirm reset password click event
  const handleConfirmResetPasswordClick = async (e) => {
    e.preventDefault(); // Prevent form submission refresh
    try {
      await confirmResetPassword({
        confirmationCode,
        newPassword,
      });
      alert("Password reset successful!");
      navigate("/confirm-reset");  // Navigate to confirm-reset after success
    } catch (error) {
      console.error("Error confirming password reset", error);
    }
  };

  return (
    <div 
      style={{ 
        backgroundColor: "#DBEAFD", 
        minHeight: "100vh", 
        padding: "5px"
      }}
    >
      <Container fluid className="d-flex justify-content-center align-items-center min-vh-100">
        <Row className="w-100 justify-content-center">
          <Col xl={8} className="p-4">
            <Row className="mb-4 text-center">
              <Col>
                <img 
                  src={lockIcon} 
                  alt="Lock Icon" 
                  className="img-fluid" 
                  style={{ maxWidth: "60px" }} 
                />
                <h4 className="mt-3">RESET PASSWORD</h4>
                <p>Enter the confirmation code and a new password below to reset your password.</p>
              </Col>
            </Row>

            {/* Reset Password Form */}
            <Form onSubmit={handleConfirmResetPasswordClick}>
              
              {/* Confirmation Code */}
              <Form.Group controlId="formConfirmationCode" className="mb-3">
                <Form.Control
                  type="text"
                  value={confirmationCode}
                  placeholder="Enter confirmation code"
                  onChange={(e) => setConfirmationCode(e.target.value)}
                />
              </Form.Group>

              {/* New Password */}
              <Form.Group controlId="formNewPassword" className="mb-3">
                <Form.Control
                  type="password"
                  value={newPassword}
                  placeholder="New Password"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Group>

              <Row className="mt-4">
                <Col xs={12} md={6}>
                  <Button variant="primary" type="submit" className="w-100">
                    Reset Password
                  </Button>
                </Col>
                <Col xs={12} md={6}>
                  <Button 
                    variant="outline-secondary" 
                    type="button" 
                    className="w-100" 
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPassword;

