import React, { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Card, Dropdown } from "react-bootstrap";
import axios from "axios";
import { API_BASE_URL } from "../apiConfig";
import { resetPassword } from "aws-amplify/auth";
import AWS from "aws-sdk";
import profileIcon from "../assets/profile.png";
import bankIcon from "../assets/banklogo.png";
import userIcon from "../assets/userIcon.png";
import profileIconBig from "../assets/profileIcon.png";
import accountIcon from "../assets/accountIcon.png";
import transactionIcon from "../assets/transactionIcon.png";
import backwardChevron from "../assets/backwardChevron.png";
import forwardChevron from "../assets/forwardChevron.png";

const AgentDashboardPage = ({ user, signOut }) => {
  const [logs, setLogs] = useState([]);
  const givenName = localStorage.getItem("givenName");
  const familyName = localStorage.getItem("familyName");
  const { getAuthHeaders } = useAuthContext();
  const [currentPage, setCurrentPage] = useState(1); // Pagination
  const logsPerPage = 5;

  const agentDetails = {
    agentId: user.userId || "N/A",
    givenName: givenName,
    familyName: familyName,
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/logs`, { headers });
        const filteredLogs = response.data
          .filter((log) => log.user_id === agentDetails.agentId)
          .sort((a, b) => new Date(b.log_datetime) - new Date(a.log_datetime));
        setLogs(filteredLogs);
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    fetchLogs();
  }, [agentDetails.agentId, getAuthHeaders]);

  const handleSignOutClick = () => {
    sessionStorage.setItem("hasLoggedIn", "false");
    signOut();
  };

  const formatOperation = (operation) => {
    switch (operation.toLowerCase()) {
      case "create":
        return "created";
      case "update":
        return "updated";
      case "delete":
        return "deleted";
      case "get":
        return "retrieved";
      default:
        return operation;
    }
  };

  const totalPages = Math.ceil(logs.length / logsPerPage);
  const currentLogs = logs.slice(
    (currentPage - 1) * logsPerPage,
    currentPage * logsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div
      style={{
        background:
          "linear-gradient(270deg, rgba(12,118,245,1) 0%, rgba(40,137,255,0.9304096638655462) 96%)",
        minHeight: "100vh",
      }}
    >
      {/* Top Bar with User Details and Logout Button */}
      <div
        className="d-flex justify-content-center align-items-between mb-3"
        style={{
          backgroundColor: "white",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <img
          src={bankIcon}
          style={{
            width: "38px",
            height: "38px",
            objectFit: "cover",
            position: "absolute",
            left: "15px",
            top: "13px",
          }}
        />

        <div className="text-center w-100" style={{ marginLeft: "85px" }}>
          <h4 style={{ marginBottom: 0, padding: "10px" }}>AGENT DASHBOARD</h4>
        </div>
        <Dropdown align="end" className="ms-3">
          <style>
            {`
          .dropdown-toggle::after {
            display: none !important;
          }
        `}
          </style>
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            <img
              src={profileIcon}
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "200px" }}>
            <Dropdown.ItemText>
              <strong>Agent ID:</strong> {agentDetails.agentId}
            </Dropdown.ItemText>
            <Dropdown.ItemText>
              <strong>Name:</strong>{" "}
              {`${agentDetails.givenName} ${agentDetails.familyName}`.trim()}
            </Dropdown.ItemText>
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleSignOutClick} className="text-danger">
              Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div
        className="container-fluid"
        style={{ marginTop: "30px", padding: "0" }}
      >
        <Button
          variant="light"
          className="mb-3 mt-2 p-3 rounded-4"
          style={{
            background:
              " linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
            border: "none",
            textAlign: "left",
            fontSize: "clamp(14px, 18px)",
            width: "170px",
            height: "160px",
            margin: "10px",
            color: "#013f8b",
          }}
          onClick={() => navigate("/create-client-account")}
        >
          <img
            src={accountIcon}
            style={{
              width: "60px",
              height: "60px",
              objectFit: "cover",
              marginBottom: "10px",
            }}
          />
          <br />
          CREATE CLIENT ACCOUNT
        </Button>
        <Button
          variant="light"
          className="mb-3 mt-2 p-3 rounded-4"
          style={{
            background:
              " linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
            border: "none",
            textAlign: "left",
            fontSize: "clamp(14px, 18px)",
            width: "170px",
            height: "160px",
            margin: "10px",
            color: "#013f8b",
          }}
          onClick={() => navigate("/create-client-profile")}
        >
          <img
            src={profileIconBig}
            style={{
              width: "55px",
              height: "55px",
              objectFit: "cover",
              marginBottom: "10px",
            }}
          />
          <br />
          CREATE CLIENT PROFILE
        </Button>
        <Button
          variant="light"
          className="mb-3 mt-2 p-3 rounded-4"
          style={{
            background:
              " linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
            border: "none",
            textAlign: "left",
            fontSize: "clamp(14px, 18px)",
            width: "170px",
            height: "160px",
            margin: "10px",
            color: "#013f8b",
          }}
          onClick={() => navigate("/agent-manage-accounts")}
        >
          <img
            src={userIcon}
            style={{
              width: "60px",
              height: "60px",
              objectFit: "cover",
              marginBottom: "10px",
            }}
          />
          <br />
          MANAGE PROFILES
        </Button>
        <Button
          variant="light"
          className="mb-3 mt-2 p-3 rounded-4"
          style={{
            background:
              " linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
            border: "none",
            textAlign: "left",
            fontSize: "clamp(14px, 18px)",
            width: "170px",
            height: "160px",
            margin: "10px",
            color: "#013f8b",
          }}
          onClick={() => navigate("/all-transactions")}
        >
          <img
            src={transactionIcon}
            style={{
              width: "60px",
              height: "60px",
              objectFit: "cover",
              marginBottom: "10px",
            }}
          />
          <br />
          VIEW ALL TRANSACTIONS
        </Button>
      </div>

      <div style={{ padding: "0px 20px 50px" }}>
        {/* Recent Activities Card */}
        <Card className="mt-4 shadow-sm" style={{ borderRadius: "15px" }}>
          <Card.Body style={{ padding: "0" }}>
            <div
              className="text-center"
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              <strong>RECENT ACTIVITIES</strong>
            </div>

            {currentLogs.length > 0 ? (
              currentLogs.map((log, index) => (
                <div
                  key={index}
                  className="p-3 text-start"
                  style={{ borderTop: "1px solid #d2d2d2" }}
                >
                  <p className="mb-0 small">
                    {log.crud_operation.toLowerCase() === "update"
                      ? `You updated ${log.attribute_name} from ${log.before_value} to ${log.after_value} for client ${log.client_id}.`
                      : `You ${formatOperation(log.crud_operation)} ${
                          log.attribute_name
                        } for client ${log.client_id}.`}
                  </p>
                  <p
                    className="mb-0 text-muted"
                    style={{ fontSize: ".75em", marginTop: "2px" }}
                  >
                    {`Date: ${new Date(log.log_datetime).toLocaleString()}`}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-center">No recent activities found.</p>
            )}
            <div className="d-flex justify-content-between mt-3">
              <Button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                style={{ background: "transparent", border: "none" }}
              >
                <img
                  src={backwardChevron}
                  style={{
                    width: "25px",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </Button>
              <span style={{ padding: "10px" }}>
                Page {currentPage} of {totalPages}
              </span>
              <Button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                style={{ background: "transparent", border: "none" }}
              >
                <img
                  src={forwardChevron}
                  style={{
                    width: "25px",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default AgentDashboardPage;
