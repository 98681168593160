import React, { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { 
  Button, 
  Container, 
  Row, 
  Col, 
  Card, 
  Form, 
  Modal
} from "react-bootstrap";
import axios from "axios";
import { API_BASE_URL } from "../apiConfig";
import backButtonImg from "../assets/backbutton.png";
import homeButtonImg from "../assets/home-button.png";

const AgentManageAccPage = ({ user }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [clientStatuses, setClientStatuses] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [headers,setHeaders] = useState(null);
  const { getAuthHeaders } = useAuthContext();
  const agentId = user.userId || "N/A";

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const headers = await getAuthHeaders();
        setHeaders(headers)
        const response = await axios.get(`${API_BASE_URL}/clients`, { headers });
        const filteredData = response.data.filter(
          client => client.agent_id === agentId
        );
        setClients(filteredData);

        const statuses = {};
        await Promise.all(
          filteredData.map(async (client) => {
            try {
              const verificationResponse = await axios.get(`${API_BASE_URL}/verification`, { headers });
              const verification = verificationResponse.data.find(
                v => v.client_id === client.client_id
              );

              statuses[client.client_id] = verification ? verification.verification_status : "No Status Available";
            } catch (statusError) {
              console.error("Error fetching status for client ${client.client_id}:", statusError);
              statuses[client.client_id] = "Unknown";
            }
          })
        );
        setClientStatuses(statuses);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    if (agentId !== "N/A") {
      fetchClients();
    }
  }, [agentId, getAuthHeaders]);

  const handleSendEmail = async () => {
    if (!selectedClientId) return;
    setLoadingEmail(true);
    try {
      const headers = await getAuthHeaders();

      const response = await axios.post(`${API_BASE_URL}/verification/email/${selectedClientId}`, { headers });
      alert("Email sent to the client successfully.");
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email to the client. Please try again.");
    } finally {
      setLoadingEmail(false);
      setShowModal(false);
    }
  };
  
  const handleDeleteClick = (client) => {
    setSelectedClient(client);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const headers = await getAuthHeaders();

      const response = await axios.put(
        `${API_BASE_URL}/clients/delete/${selectedClient.client_id}`,
        {
          deleted_by_id: user.userId,
          deleted_by_name: user.username,
        },
        { headers:
          { ...headers,
            "Content-Type": "application/json"
          } 
        }
      );

      if (response.status === 200) {
        setClients(
          clients.filter(
            client => client.client_id !== selectedClient.client_id
          )
        );
        setShowDeleteModal(false);
      } else {
        console.error("Failed to delete client profile.");
        alert("Error deleting profile.");
      }
    } catch (error) {
      console.error("Error deleting client profile:", error);
      alert("Error deleting profile.");
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClose = () => setShowModal(false);
  const handleCancelDelete = () => setShowDeleteModal(false);
  const handleEditClick = (client) => {
    axios
      .get(`${API_BASE_URL}/clients/${client.client_id}?agentname=${user.givenName} ${user.familyName}&agentid=${agentId}`, { headers })
      .then(() => {
        navigate(`/update-client-profile`, { state: { clientData: client } });
      })
      .catch((error) => {
        console.error("Axios request failed:", error);
      });
  };
  const handleCreateProfileClick = () => navigate('/create-client-profile');
  const handleViewLinkedAccounts = (client) => navigate(`/linked-accounts/${client.client_id}`, { state: { client } });
  const handleViewTransactions = (client) => navigate(`/transactions/${client.client_id}`, { state: { client } });

  const handleStatusClick = (client) => {
    const status = clientStatuses[client.client_id];
    if (status === "Verified") {
      navigate("/view-verified-client-details");
    } else if (status === "Pending") {
      navigate("/list-of-clients-for-approval");
    } else if (status === "Rejected" || status === "Not Verified") {
      setSelectedClientId(client.client_id);
      setShowModal(true);
    }
  };

  const filteredClients = clients.filter(client =>
    client.first_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
 
  return (
    <div 
      style={{
        backgroundColor: "#0D75F4",
        minHeight: "100vh",
        padding: "5px",
        background:
          "linear-gradient(270deg, rgba(12,118,245,1) 0%, rgba(40,137,255,0.9304096638655462) 96%)",
      }}
    >
      <Container fluid className="pt-3 pb-3 px-3">
        {/* Top Bar: Back Button, Search Bar, and Filter Button */}
        <Row className="mb-3 align-items-center">
          <Col xs={2} className="text-start">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate(-1)}
            >
              <img 
                src={backButtonImg}
                alt="Back"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%"
                }}
              />
            </Button>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="search"
              placeholder="Search Client Name"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Col>
          <Col xs={2} className="text-end">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate("/")}
            >
              <img
                src={homeButtonImg} 
                alt="Home" 
                style={{ 
                  width: "75%", 
                  height: "75%", 
                  objectFit: "cover", 
                  borderRadius: "0%" 
                }} 
              />
            </Button>
          </Col>
        </Row>

        {/* Client List */}
        <Card
          className="mb-3"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <h4 className="mb-3 text-white" style={{ textAlign: "center" }}>
            CLIENT PROFILE LIST
          </h4>

          {filteredClients.map((client, index) => {
            const status = clientStatuses[client.client_id];
            return (
              <Card 
                key={index} 
                className="mb-3" 
                style={{ 
                  borderRadius: "15px", 
                  background:
                    " linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
                  border: "none", 
                }}
              >
                <Card.Body>
                  <Row className="align-items-center">
                    <Col>
                      <div className="d-flex flex-column flex-md-row align-items-md-center">
                        <p className="mb-0 me-md-5">
                          {client.first_name} {client.last_name}
                        </p>
                        <p className="mb-0">Client ID: {client.client_id}</p>
                      </div>
                      <p 
                        onClick={() => handleStatusClick(client)} 
                        className="mb-0 small" 
                        style={{ color: "#0D75F4", cursor: "pointer" }}>
                        Status: {status || "Loading..."}
                      </p>
                      {(status !== "Rejected" && 
                        status !== "Not Verified" && 
                        status !== "Pending") && (
                        <>
                          <p 
                            onClick={() => 
                              handleViewLinkedAccounts(client)
                            } 
                            className="small"
                            style={{
                              marginTop: "10px",
                              marginBottom: "3px",
                              color: "rgb(18, 94, 171)",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          >
                            View Linked Accounts
                          </p>
                          <p 
                            onClick={() => 
                              handleViewTransactions(client)} 
                            className="small"
                            style={{
                              marginTop: "0px",
                              marginBottom: "3px",
                              color: "rgb(18, 94, 171)",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          >
                            View Transactions
                          </p>
                        </>
                      )}
                    </Col>
                    {/* Buttons */}
                    <Col 
                      xs={2} 
                      className="d-none d-md-flex flex-column align-items-end"
                    >
                      <Button 
                        variant="outline-primary" 
                        className="mb-2 rounded-4" 
                        style={{ width: "100%" }}
                        onClick={() => handleEditClick(client)}
                      >
                        View / Edit
                      </Button>
                      <Button 
                        variant="outline-danger" 
                        className="mb-2 rounded-4" 
                        style={{ width: "100%" }}
                        onClick={() => handleDeleteClick(client)}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>

                  {/* Small screen buttons */}
                  <Row className="d-md-none mt-3">
                    <Col className="d-flex justify-content-between">
                      <Button 
                        variant="outline-primary" 
                        className="me-2 rounded-4" 
                        style={{ flex: "1" }}
                        onClick={() => handleEditClick(client)}>View / Edit</Button>
                      <Button 
                        variant="outline-danger" 
                        className="rounded-4" 
                        style={{ flex: "1" }}
                        onClick={() => handleDeleteClick(client)}>Delete</Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          })}

          <Button 
            variant="light" 
            className="mb-3 mt-2 p-3 w-100 rounded-4" 
            style={{ backgroundColor: "#ECEFF1" }} 
            onClick={handleCreateProfileClick}
          >
            CREATE NEW CLIENT PROFILE
          </Button>
        </Card>
      </Container>

      {/* Modal for sending email */}
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>SEND EMAIL TO CLIENT?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to trigger an email to the client?
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSendEmail}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleCancelDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>DELETE ACCOUNT?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Client Profile? 
            All linked accounts will be deleted.
            You can’t undo this action.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={handleCancelDelete}
          >
            Cancel
          </Button>
          <Button 
            variant="danger" 
            onClick={handleConfirmDelete}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AgentManageAccPage;
