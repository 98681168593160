// src/pages/AgentCreateClientAccount.js
import React, { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import profileIcon from "../assets/profileIcon.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import backButtonImg from "../assets/backbutton.png";
import { API_BASE_URL } from "../apiConfig";
import AWS from "aws-sdk";

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.Credentials(
    process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  ),
});

const cognito = new AWS.CognitoIdentityServiceProvider();

const CreateClientAccount = ({ user }) => {
  const navigate = useNavigate();
  const givenName = localStorage.getItem("givenName") || "N/A";
  const familyName = localStorage.getItem("familyName") || "N/A";

  const userDetails = {
    userId: user?.userId || "N/A",
    email: user?.username || "N/A",
    givenName,
    familyName,
  };

  const [formData, setFormData] = useState({
    clientId: "",
    accountType: "",
    accountStatus: "Active",
    openingDate: "",
    initialDeposit: "",
    currency: "",
    branchId: "",
  });

  const [agentId, setAgentId] = useState(userDetails.agentId);
  const [isAgent, setIsAgent] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [verifiedClients, setVerifiedClients] = useState([]);
  const { getAuthHeaders } = useAuthContext();

  const [errors, setErrors] = useState({});

  const errorStyle = {
    border: "1px solid red",
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "clientId":
        if (!value) {
          error = "Client ID is required.";
        }
        break;
      case "accountType":
        if (!value) {
          error = "Account type is required.";
        }
        break;
      case "accountStatus":
        if (!["Active", "Inactive", "Pending"].includes(value)) {
          error = "Account status must be 'Active', 'Inactive', or 'Pending'.";
        }
        break;
      case "openingDate":
        if (!value) {
          error = "Opening date is required.";
        } else if (new Date(value) > new Date()) {
          error = "Opening date must be in the past.";
        }
        break;
      case "initialDeposit":
        if (!value || isNaN(value) || parseFloat(value) < 0) {
          error = "Initial deposit must be a non-negative number.";
        }
        break;
      case "currency":
        if (!["SGD", "USD", "EUR", "JPY", "AUD"].includes(value)) {
          error = "Currency must be one of SGD, USD, EUR, JPY, AUD.";
        }
        break;
      case "branchId":
        if (!value) {
          error = "Branch ID is required.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  useEffect(() => {
    const fetchAgentId = async () => {
      try {
        const username = localStorage.getItem("username");
        if (!username) {
          console.error("No username found in localStorage");
          return;
        }

        const params = {
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Username: username,
        };

        const groupData = await cognito
          .adminListGroupsForUser(params)
          .promise();
        const groups = groupData.Groups.map((group) => group.GroupName);

        if (groups.includes("agents")) {
          setIsAgent(true);
          const response = await cognito.adminGetUser(params).promise();
          const userId =
            response.UserAttributes.find((attr) => attr.Name === "sub")
              ?.Value || "No sub";
          setAgentId(userId);
        } else if (groups.includes("root_admin")) {
          setIsAdmin(true);
        } else {
          setIsAgent(false);
          setIsAdmin(false);
          setAgentId("");
        }
      } catch (error) {
        console.error("Error fetching user groups or ID:", error);
      }
    };

    fetchAgentId();
  }, []);

  useEffect(() => {
    const fetchVerifiedClients = async () => {
      try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/verification`, {
          headers,
        });
        const verifications = response.data;

        const verifiedClientIds = verifications
          .filter((v) => v.verification_status === "Verified")
          .map((v) => v.client_id);

        if (isAgent) {
          // If the user is an agent, filter clients by agentId
          const clientsResponse = await axios.get(`${API_BASE_URL}/clients`, {
            headers,
          });
          const agentClients = clientsResponse.data.filter(
            (client) =>
              client.agent_id === agentId &&
              verifiedClientIds.includes(client.client_id)
          );
          setVerifiedClients(agentClients);
        } else {
          // If the user is an admin, show all verified clients
          const clientsResponse = await axios.get(`${API_BASE_URL}/clients`, {
            headers,
          });
          const allVerifiedClients = clientsResponse.data.filter((client) =>
            verifiedClientIds.includes(client.client_id)
          );
          setVerifiedClients(allVerifiedClients);
        }
      } catch (error) {
        console.error("Error fetching verified clients:", error);
      }
    };

    fetchVerifiedClients();
  }, [agentId, isAgent]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    const error = validateField(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};
    Object.keys(formData).forEach((field) => {
      const error = validateField(field, formData[field]);
      if (error) validationErrors[field] = error;
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      alert("Please fix the errors before submitting.");
      return;
    }

    try {
      const headers = await getAuthHeaders();

      const requestData = {
        client_id: parseInt(formData.clientId),
        account_type: formData.accountType,
        account_status: formData.accountStatus,
        opening_date: formData.openingDate,
        initial_deposit: parseFloat(formData.initialDeposit),
        currency: formData.currency,
        branch_id: formData.branchId,
        created_by_id: userDetails.userId,
        created_by_name: `${userDetails.givenName} ${userDetails.familyName}`,
      };

      const response = await axios.post(
        `${API_BASE_URL}/accounts`,
        requestData,
        {
          headers: {
            ...headers,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        alert("Client account created successfully!");
        navigate(-1);
      } else {
        alert("Failed to create client account.");
      }
    } catch (error) {
      console.error(
        "Error creating client account:",
        error.response?.data || error.message
      );
      alert("An error occurred while creating the client account.");
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#DBEAFD",
        minHeight: "100vh",
        padding: "5px",
      }}
    >
      <Container fluid className="pt-3 pb-3 px-3">
        <Row className="mb-3 align-items-center">
          <Col xs={2} className="text-start">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate(-1)}
            >
              <img
                src={backButtonImg}
                alt="Back"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Button>
          </Col>
        </Row>

        <Col>
          <Row className="mb-4 text-center">
            <Col>
              <img
                src={profileIcon}
                alt="Profile Icon"
                className="img-fluid"
                style={{ maxWidth: "60px" }}
              />
              <h4 className="mt-3">CLIENT ACCOUNT</h4>
            </Col>
          </Row>
        </Col>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formClientId" className="mb-3">
            <Form.Select
              name="clientId"
              value={formData.clientId}
              onChange={handleInputChange}
              style={{ height: "50px" }}
            >
              <option value="">Select Verified Client</option>
              {verifiedClients.map((client) => (
                <option key={client.client_id} value={client.client_id}>
                  {client.first_name} {client.last_name} (ID: {client.client_id}
                  )
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {/* The rest of the form fields */}
          <Form.Group controlId="formAccountType" className="mb-3">
            <Form.Select
              name="accountType"
              value={formData.accountType}
              onChange={handleInputChange}
              style={{ height: "50px", marginBottom: "15px" }}
            >
              <option value="">Account Type</option>
              <option value="Savings">Savings Account</option>
              <option value="Current">Current Account</option>
              <option value="Fixed Deposit">Fixed Deposit Account</option>
            </Form.Select>
            {errors.accountType && (
              <p style={{ color: "red" }}>{errors.accountType}</p>
            )}
            <Form.Group controlId="formAccountStatus" className="mb-3">
              <Form.Select
                name="accountStatus"
                value={formData.accountStatus}
                onChange={handleInputChange}
                style={{ height: "50px" }}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                <option value="Pending">Pending</option>
              </Form.Select>
              {errors.accountStatus && (
                <p style={{ color: "red" }}>{errors.accountStatus}</p>
              )}
            </Form.Group>
          </Form.Group>
          <Form.Group controlId="formOpeningDate" className="mb-3">
            <Form.Control
              type="date"
              name="openingDate"
              value={formData.openingDate}
              placeholder="Opening Date"
              onChange={handleInputChange}
              style={{
                ...(errors.openingDate ? errorStyle : {}),
                height: "50px",
              }}
            />
            {errors.openingDate && (
              <p style={{ color: "red" }}>{errors.openingDate}</p>
            )}
          </Form.Group>
          <Form.Group controlId="formInitialDeposit" className="mb-3">
            <Form.Control
              type="number"
              name="initialDeposit"
              value={formData.initialDeposit}
              placeholder="Initial Deposit"
              onChange={handleInputChange}
              style={{ height: "50px" }}
            />
            {errors.initialDeposit && (
              <p style={{ color: "red" }}>{errors.initialDeposit}</p>
            )}
          </Form.Group>
          <Form.Group controlId="formCurrency" className="mb-3">
            <Form.Select
              name="currency"
              value={formData.currency}
              onChange={handleInputChange}
              style={{ height: "50px" }}
            >
              <option value="">Currency</option>
              <option value="SGD">SGD</option>
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="JPY">JPY</option>
              <option value="AUD">AUD</option>
            </Form.Select>
            {errors.currency && (
              <p style={{ color: "red" }}>{errors.currency}</p>
            )}
          </Form.Group>
          <Form.Group controlId="formBranchId" className="mb-3">
            <Form.Control
              type="text"
              name="branchId"
              value={formData.branchId}
              placeholder="Branch ID"
              onChange={handleInputChange}
              style={{ height: "50px" }}
            />
            {errors.branchId && (
              <p style={{ color: "red" }}>{errors.branchId}</p>
            )}
          </Form.Group>
          <Row className="mt-4">
            <Col xs={12} md={6}>
              <Button
                variant="primary"
                type="submit"
                className="w-100"
                style={{ height: "50px" }}
              >
                Create
              </Button>
            </Col>
            <Col xs={12} md={6} style={{ marginTop: "10px" }}>
              <Button
                variant="outline-secondary"
                type="button"
                className="w-100"
                onClick={() => navigate(-1)}
                style={{ height: "50px" }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default CreateClientAccount;
