import React, { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Card, Dropdown } from "react-bootstrap";
import axios from "axios";
import { API_BASE_URL } from "../apiConfig";
import { resetPassword } from "aws-amplify/auth";
import AWS from "aws-sdk";
import profileIcon from "../assets/profile.png";
import bankIcon from "../assets/banklogo.png";
import userIcon from "../assets/userIcon.png";
import agentIcon from "../assets/agentIcon.png";
import passwordIcon from "../assets/passwordIcon.png";
import transactionIcon from "../assets/transactionIcon.png";
import backwardChevron from "../assets/backwardChevron.png";
import forwardChevron from "../assets/forwardChevron.png";

const AdminDashboardPage = ({ user, signOut }) => {
  const [logs, setLogs] = useState([]);
  const givenName = localStorage.getItem("givenName");
  const familyName = localStorage.getItem("familyName");
  const { getAuthHeaders } = useAuthContext();
  const [currentPage, setCurrentPage] = useState(1);
  const logsPerPage = 5;

  const adminDetails = {
    adminId: user.userId || "N/A",
    givenName: givenName,
    familyName: familyName,
  };

  // useEffect(() => {
  //   console.log("User object in AdminDashboardPage:", user);
  // }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/logs`, { headers });
        const sortedLogs = response.data.sort(
          (a, b) => new Date(b.log_datetime) - new Date(a.log_datetime)
        );
        setLogs(sortedLogs); // Store all sorted logs
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    fetchLogs();
  }, [adminDetails.adminId, getAuthHeaders]);

  const handleSignOutClick = () => {
    sessionStorage.setItem("hasLoggedIn", "false");
    console.log("Set 'hasLoggedIn' to false");
    signOut();
  };

  const handleResetPasswordClick = async () => {
    const enteredUsername = prompt("Please enter your username or email");

    if (enteredUsername) {
      try {
        const output = await resetPassword({ username: enteredUsername });
        const { nextStep } = output;

        if (nextStep.resetPasswordStep === "CONFIRM_RESET_PASSWORD_WITH_CODE") {
          
          navigate("/reset-password");
        } else if (nextStep.resetPasswordStep === "DONE") {
          console.log("Successfully reset password.");
        } else {
          console.log("Unknown step:", nextStep);
        }
      } catch (error) {
        console.error("Error during reset password process", error);
      }
    }
  };

  const formatOperation = (operation) => {
    switch (operation.toLowerCase()) {
      case "create":
        return "created";
      case "update":
        return "updated";
      case "delete":
        return "deleted";
      case "get":
        return "retrieved";
      default:
        return operation;
    }
  };

  const totalLogs = logs.length;
  const totalPages = Math.ceil(totalLogs / logsPerPage);
  const currentLogs = logs.slice(
    (currentPage - 1) * logsPerPage,
    currentPage * logsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div
      style={{
        background:
          "linear-gradient(270deg, rgba(12,118,245,1) 0%, rgba(40,137,255,0.9304096638655462) 96%)",
        minHeight: "100vh",
      }}
    >
      {/* Top Bar with User Details and Logout Button */}
      <div
        className="d-flex justify-content-center align-items-between mb-3"
        style={{
          backgroundColor: "white",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <img
          src={bankIcon}
          style={{
            width: "38px",
            height: "38px",
            objectFit: "cover",
            position: "absolute",
            left: "15px",
            top: "13px",
          }}
        />

        <div className=" text-center w-100" style={{ marginLeft: "85px" }}>
          <h4 style={{ marginBottom: 0, padding: "10px" }}>ADMIN DASHBOARD</h4>
        </div>
        <Dropdown align="end" className="ms-3">
          <style>
            {`
          .dropdown-toggle::after {
            display: none !important;
          }
        `}
          </style>
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            <img
              src={profileIcon}
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "200px" }}>
            <Dropdown.ItemText>
              <strong>Admin ID:</strong> {adminDetails.adminId}
            </Dropdown.ItemText>
            <Dropdown.ItemText>
              <strong>Name:</strong>{" "}
              {`${adminDetails.givenName} ${adminDetails.familyName}`.trim()}
            </Dropdown.ItemText>
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleSignOutClick} className="text-danger">
              Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        className="container-fluid"
        style={{ marginTop: "30px", padding: "0" }}
      >
        <Button
          variant="light"
          className="mb-3 mt-2 p-3 rounded-4"
          style={{
            background:
              " linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
            border: "none",
            textAlign: "left",
            fontSize: "clamp(14px, 18px)",
            width: "170px",
            height: "160px",
            margin: "10px",
            color: "#013f8b",
          }}
          onClick={() => navigate("/admin-create-agent")}
        >
          <img
            src={agentIcon}
            style={{
              width: "65px",
              height: "65px",
              objectFit: "cover",
              marginBottom: "10px",
            }}
          />
          <br />
          CREATE AGENT
        </Button>
        <Button
          variant="light"
          className="mb-3 mt-2 p-3 rounded-4"
          style={{
            background:
              " linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
            border: "none",
            textAlign: "left",
            fontSize: "clamp(14px, 18px)",
            width: "170px",
            height: "160px",
            margin: "10px",
            color: "#013f8b",
          }}
          onClick={() => navigate("/admin-manage-client-accounts")}
        >
          <img
            src={userIcon}
            style={{
              width: "60px",
              height: "60px",
              objectFit: "cover",
              marginBottom: "10px",
            }}
          />
          <br />
          MANAGE
          <br />
          ALL USERS
        </Button>
        <Button
          variant="light"
          className="mb-3 mt-2 p-3  rounded-4"
          style={{
            background:
              " linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
            border: "none",
            textAlign: "left",
            fontSize: "clamp(14px, 18px)",
            width: "170px",
            height: "160px",
            margin: "10px",
            color: "#013f8b",
          }}
          onClick={() => navigate("/all-transactions")}
        >
          <img
            src={transactionIcon}
            style={{
              width: "60px",
              height: "60px",
              objectFit: "cover",
              marginBottom: "10px",
            }}
          />
          <br />
          VIEW ALL TRANSACTIONS
        </Button>
        <Button
          variant="light"
          className="mb-3 mt-2 p-3  rounded-4"
          style={{
            background:
              " linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
            border: "none",
            textAlign: "left",
            whiteSpace: "nowrap",
            fontSize: "clamp(14px, 18px)",
            width: "170px",
            height: "160px",
            margin: "10px",
            color: "#013f8b",
          }}
          onClick={handleResetPasswordClick}
        >
          <img
            src={passwordIcon}
            style={{
              width: "60px",
              height: "60px",
              objectFit: "cover",
              marginBottom: "10px",
            }}
          />
          <br />
          RESET
          <br />
          PASSWORD
        </Button>
      </div>
      <div style={{ padding: "0px 20px 50px" }}>
        {/* Recent Activities Card */}
        <Card className="mt-4 shadow-sm" style={{ borderRadius: "15px" }}>
          <Card.Body style={{ padding: "0" }}>
            <div
              className="text-center"
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              <strong>RECENT ACTIVITIES</strong>
            </div>
            {currentLogs.length > 0 ? (
              currentLogs.map((log, index) => (
                <div
                  key={index}
                  className="p-3 text-start"
                  style={{ borderTop: "1px solid #d2d2d2" }}
                >
                  <p className="mb-0 small">
                    {log.crud_operation.toLowerCase() === "update"
                      ? `${
                          log.user_id === user.userId
                            ? "You"
                            : log.user_name || "Unknown"
                        } ${formatOperation(log.crud_operation)} ${
                          log.attribute_name
                        } from ${log.before_value} to ${
                          log.after_value
                        } for client ${log.client_id}.`
                      : `${
                          log.user_id === user.userId
                            ? "You"
                            : log.user_name || "Unknown"
                        } ${formatOperation(log.crud_operation)} ${
                          log.attribute_name
                        } for client ${log.client_id}.`}
                  </p>
                  <p
                    className="mb-0 text-muted"
                    style={{ fontSize: ".75em", marginTop: "2px" }}
                  >
                    {`Date: ${new Date(log.log_datetime).toLocaleString()}`}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-center">No recent activities found.</p>
            )}
            <div className="d-flex justify-content-between mt-3">
              <Button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                style={{ background: "transparent", border: "none" }}
              >
                <img
                  src={backwardChevron}
                  style={{
                    width: "25px",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </Button>
              <span style={{ padding: "10px" }}>
                Page {currentPage} of {totalPages}
              </span>
              <Button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                style={{ background: "transparent", border: "none" }}
              >
                <img
                  src={forwardChevron}
                  style={{
                    width: "25px",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default AdminDashboardPage;
