import React, { createContext, useContext } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const getAuthHeaders = async () => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens?.idToken?.toString();
      if (token) {
        return {
          'Authorization': `Bearer ${token}`,
        };
      } else {
        console.warn('Token not available');
        return {};
      }
    } catch (error) {
      console.error('Error fetching the JWT token:', error);
      return {};
    }
  };

  return (
    <AuthContext.Provider value={{ getAuthHeaders }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

// import React, { createContext, useState, useEffect, useContext } from 'react';
// import { fetchAuthSession } from 'aws-amplify/auth';

// export const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [headers, setHeaders] = useState({});

//   useEffect(() => {
//     const getIdToken = async () => {
//       try {
//         const session = await fetchAuthSession();
//         const token = session.tokens?.idToken?.toString();
//         if (token) {
//           const authHeaders = {
//             'Authorization': `Bearer ${token}`,
//           };
//           setHeaders(authHeaders);
//           console.log('Authorization headers set:', authHeaders);
//         }
//       } catch (error) {
//         console.error('Error fetching the JWT token:', error);
//       }
//     };

//     getIdToken();
//   }, []);

//   return (
//     <AuthContext.Provider value={{ headers }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuthContext = () => useContext(AuthContext);
