import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  Form,
  Modal,
} from "react-bootstrap";
import backButtonImg from "../assets/backbutton.png";
import homeButtonImg from "../assets/home-button.png";
import AWS from "aws-sdk";

const AdminManageAgentAccPage = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [agentList, setAgentList] = useState([]); // Store fetched agents

  const cognito = new AWS.CognitoIdentityServiceProvider();

  const fetchAgentUsers = async () => {
    try {
      const params = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        GroupName: "agents",
      };

      const response = await cognito.listUsersInGroup(params).promise();

      const agents = response.Users.map((user) => ({
        id:
          user.Attributes.find((attr) => attr.Name === "sub")?.Value ||
          "No sub",
        name: `${
          user.Attributes.find((attr) => attr.Name === "given_name")?.Value ||
          ""
        } ${
          user.Attributes.find((attr) => attr.Name === "family_name")?.Value ||
          ""
        }`.trim(),
        email:
          user.Attributes.find((attr) => attr.Name === "email")?.Value ||
          "No Email",
        status: user.Enabled ? "Enabled" : "Disabled",
      }));

      setAgentList(agents);
    } catch (error) {
      console.error("Error fetching users from Cognito:", error);
    }
  };

  const handleToggleAgentStatus = async (agent) => {
    setSelectedAgent(agent);
    setShowConfirmationModal(true);
  };

  const handleConfirmToggle = async () => {
    if (selectedAgent) {
      const isCurrentlyDisabled = selectedAgent.status === "Disabled";
      const params = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        Username: selectedAgent.email,
      };

      try {
        if (isCurrentlyDisabled) {
          await cognito.adminEnableUser(params).promise();
        } else {
          await cognito.adminDisableUser(params).promise();
        }
        await fetchAgentUsers();
      } catch (error) {
        console.error("Error updating agent status in Cognito:", error);
      } finally {
        setShowConfirmationModal(false);
        setSelectedAgent(null);
      }
    }
  };

  const handleCancelToggle = () => {
    setShowConfirmationModal(false);
    setSelectedAgent(null);
  };

  useEffect(() => {
    fetchAgentUsers();
  }, []);

  const handleSearch = (e) => setSearchTerm(e.target.value);

  const filteredAgents = agentList.filter((agent) =>
    agent.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      style={{
        minHeight: "100vh",
        padding: "5px",
        background:
          "linear-gradient(270deg, rgba(12,118,245,1) 0%, rgba(40,137,255,0.9304096638655462) 96%)",
      }}
    >
      <Container fluid className="pt-3 pb-3 px-3">
        {/* Top Bar: Back Button, Search, and Home Button */}
        <Row className="mb-3 align-items-center">
          <Col xs={2} className="text-start">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate(-1)}
            >
              <img
                src={backButtonImg}
                alt="Back"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Button>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="search"
              placeholder="Search Agent Name"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Col>
          <Col xs={2} className="text-end">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate("/")}
            >
              <img
                src={homeButtonImg}
                alt="Home"
                style={{
                  width: "75%",
                  height: "75%",
                  objectFit: "cover",
                  borderRadius: "0%",
                }}
              />
            </Button>
          </Col>
        </Row>

        {/* Clients and Agents buttons outside of the white card */}
        <Row className="mb-3">
          <Col xs={4}>
            <Button
              variant="light"
              className="w-100 rounded-4"
              style={{
                backgroundColor: "#FFFFFF",
                color: "#817F7F",
                border: "none",
              }}
              onClick={() => navigate("/admin-manage-admin-accounts")}
            >
              Admin
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              variant="light"
              className="w-100 rounded-4"
              style={{ backgroundColor: "#CFE3FD" }} // Selected color for "Agents" tab
              onClick={() => navigate("/admin-manage-agent-accounts")}
            >
              Agents
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              variant="light"
              className="w-100 rounded-4"
              style={{
                backgroundColor: "#FFFFFF",
                color: "#817F7F",
                border: "none",
              }}
              onClick={() => navigate("/admin-manage-client-accounts")}
            >
              Clients
            </Button>
          </Col>
        </Row>

        <Card
          className="mb-3"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <h4 className="mb-3 text-white" style={{ textAlign: "center" }}>
            AGENT ACCOUNT LIST
          </h4>

          {filteredAgents.map((agent) => (
            <Card
              key={agent.id}
              className="mb-3"
              style={{
                borderRadius: "15px",
                background:
                  agent.status === "Disabled"
                    ? "#c2c2c2"
                    : "linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
              }}
            >
              <Card.Body>
                <Row className="align-items-center">
                  <Col xs={10} className="w-100">
                    <div className="d-flex flex-column flex-md-row align-items-md-center">
                      <p className="mb-0">{agent.name}</p>
                      <p className="mb-0 ms-md-5 small">Agent ID: {agent.id}</p>
                      <p className="mb-0 ms-md-5 small">Email: {agent.email}</p>
                    </div>
                    <p
                      className="small"
                      onClick={() =>
                        navigate("/admin-view-client", {
                          state: { agentId: agent.id },
                        })
                      } // Pass agentId in state
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        color: "rgb(18, 94, 171)",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      View Clients
                    </p>
                  </Col>

                  {/* Stack buttons vertically on larger screens */}
                  <Col
                    xs={2}
                    className="d-none d-md-flex flex-column align-items-end"
                  >
                    <Button
                      variant="outline-primary"
                      className="mb-2 rounded-4"
                      style={{ width: "100%" }}
                      onClick={() =>
                        navigate("/admin-update-agent", {
                          state: { email: agent.email },
                        })
                      }
                    >
                      View / Edit
                    </Button>
                    <Button
                      variant={
                        agent.status === "Disabled"
                          ? "outline-success"
                          : "outline-danger"
                      }
                      className="rounded-4"
                      style={{ width: "100%" }}
                      onClick={() => handleToggleAgentStatus(agent)}
                    >
                      {agent.status === "Disabled" ? "Enable" : "Disable"}
                    </Button>
                  </Col>

                  {/* On small screens, show buttons side by side below the name */}
                  <Col className="d-md-none w-100 ">
                    <Col className="d-flex justify-content-between">
                      <Button
                        variant="outline-primary"
                        className="mb-2 rounded-4"
                        style={{ flex: "1", marginRight: "5px" }}
                        onClick={() =>
                          navigate("/admin-update-agent", {
                            state: { email: agent.email },
                          })
                        }
                      >
                        View / Edit
                      </Button>
                      <Button
                        variant={
                          agent.status === "Disabled"
                            ? "outline-success"
                            : "outline-danger"
                        }
                        className="rounded-4"
                        style={{
                          flex: "1",
                          height: "37px",
                          marginLeft: "15px",
                        }}
                        onClick={() => handleToggleAgentStatus(agent)}
                      >
                        {agent.status === "Disabled" ? "Enable" : "Disable"}
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))}

          <Button
            variant="light"
            className="mb-3 mt-2 p-3 w-100 rounded-4"
            onClick={() => navigate("/admin-create-agent")}
          >
            CREATE NEW AGENT
          </Button>
        </Card>
      </Container>

      <Modal show={showConfirmationModal} onHide={handleCancelToggle} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedAgent?.status === "Disabled"
              ? "Enable Agent?"
              : "Disable Agent?"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to{" "}
          {selectedAgent?.status === "Disabled" ? "enable" : "disable"} the
          agent?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelToggle}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmToggle}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminManageAgentAccPage;
