import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  Form,
  Modal,
} from "react-bootstrap";
import backButtonImg from "../assets/backbutton.png";
import homeButtonImg from "../assets/home-button.png";
import AWS from "aws-sdk";

const AdminManageAdminAccPage = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [adminList, setAdminList] = useState([]); // Store fetched admins

  const cognito = new AWS.CognitoIdentityServiceProvider();

  const fetchAdminUsers = async () => {
    try {
      const params = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        GroupName: "admins",
      };

      const response = await cognito.listUsersInGroup(params).promise();

      const admins = response.Users.map((user) => ({
        id:
          user.Attributes.find((attr) => attr.Name === "sub")?.Value || "No ID",
        name: `${
          user.Attributes.find((attr) => attr.Name === "given_name")?.Value ||
          ""
        } ${
          user.Attributes.find((attr) => attr.Name === "family_name")?.Value ||
          ""
        }`.trim(),
        email:
          user.Attributes.find((attr) => attr.Name === "email")?.Value ||
          "No Email",
      }));

      setAdminList(admins);
    } catch (error) {
      console.error("Error fetching admins from Cognito:", error);
    }
  };

  useEffect(() => {
    fetchAdminUsers();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDeleteClick = (admin) => {
    setSelectedAdmin(admin);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedAdmin) {
      try {
        const params = {
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Username: selectedAdmin.email,
        };

        await cognito.adminDeleteUser(params).promise();

        setAdminList(
          adminList.filter((admin) => admin.id !== selectedAdmin.id)
        );
      } catch (error) {
        console.error("Error deleting admin:", error);
        alert("Failed to delete admin. Please try again.");
      } finally {
        setShowDeleteModal(false); // Close the modal
        setSelectedAdmin(null); // Clear the selected admin
      }
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setSelectedAdmin(null);
  };

  const filteredAdmins = adminList.filter((admin) =>
    admin.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      style={{
        background:
          "linear-gradient(270deg, rgba(12,118,245,1) 0%, rgba(40,137,255,0.9304096638655462) 96%)",
        minHeight: "100vh",
        padding: "5px",
      }}
    >
      <Container fluid className="pt-3 pb-3 px-3">
        {/* Top Bar: Back Button, Search, and Home Button */}
        <Row className="mb-3 align-items-center">
          <Col xs={2} className="text-start">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate(-1)}
            >
              <img
                src={backButtonImg}
                alt="Back"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Button>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="search"
              placeholder="Search Admin Name"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Col>
          <Col xs={2} className="text-end">
            <Button
              variant="light"
              className="p-0"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              onClick={() => navigate("/")}
            >
              <img
                src={homeButtonImg}
                alt="Home"
                style={{
                  width: "75%",
                  height: "75%",
                  objectFit: "cover",
                  borderRadius: "0%",
                }}
              />
            </Button>
          </Col>
        </Row>

        {/* Tab Navigation */}
        <Row className="mb-3">
          <Col xs={4}>
            <Button
              variant="light"
              className="w-100 rounded-4"
              style={{ backgroundColor: "#CFE3FD" }}
            >
              Admin
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              variant="light"
              className="w-100 rounded-4"
              style={{ backgroundColor: "#FFFFFF", color: "#817F7F" }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#CFE3FD";
                e.target.style.color = "#000000";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#FFFFFF";
                e.target.style.color = "#817F7F";
              }}
              onClick={() => navigate("/admin-manage-agent-accounts")}
            >
              Agents
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              variant="light"
              className="w-100 rounded-4"
              style={{ backgroundColor: "#FFFFFF", color: "#817F7F" }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#CFE3FD";
                e.target.style.color = "#000000";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#FFFFFF";
                e.target.style.color = "#817F7F";
              }}
              onClick={() => navigate("/admin-manage-client-accounts")}
            >
              Clients
            </Button>
          </Col>
        </Row>

        {/* Admin List */}
        <Card
          className="mb-3"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <h4 className="mb-3 text-white">ADMIN ACCOUNT LIST</h4>

          {filteredAdmins.map((admin, index) => (
            <Card
              key={index}
              className="mb-3"
              style={{
                borderRadius: "15px",
                background:
                  "linear-gradient(95deg, rgba(228,245,254,1) 0%, rgba(187,231,255,1) 96%)",
              }}
            >
              <Card.Body>
                <Col className="align-items-center w-100">
                  <Col className="w-100">
                    <div className="d-flex flex-column flex-md-row align-items-md-center">
                      <h5 className="mb-0">{admin.name}</h5>
                      <p className="mb-0 ms-md-5 small">Admin ID: {admin.id}</p>
                      <p className="mb-0 ms-md-5 small">Email: {admin.email}</p>
                    </div>
                  </Col>

                  <Col className="d-md-none w-100 mt-3">
                    <Col className="d-flex justify-content-between">
                      <Button
                        variant="outline-primary"
                        className="mb-2 rounded-4"
                        style={{ flex: "1", marginRight: "5px" }}
                        onClick={() =>
                          navigate("/root-update-admin", {
                            state: { email: admin.email },
                          })
                        } // Pass email
                      >
                        View / Edit
                      </Button>
                      <Button
                        variant="outline-danger"
                        className="rounded-4"
                        style={{
                          flex: "1",
                          height: "37px",
                          marginLeft: "15px",
                        }}
                        onClick={() => handleDeleteClick(admin)}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Col>
                </Col>
              </Card.Body>
            </Card>
          ))}

          <Button
            variant="light"
            className="mb-3 mt-2 p-3 w-100 rounded-4"
            style={{ backgroundColor: "#ECEFF1" }}
            onClick={() => navigate("/root-create-admin")}
          >
            CREATE NEW ADMIN
          </Button>
        </Card>
      </Container>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleCancelDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>DELETE ADMIN ACCOUNT?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this admin account? This action cannot
          be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminManageAdminAccPage;
